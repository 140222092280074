import React from "react";

// You need to pass the unit for width & height along with the numbers
function MediaContent({
                          mediaSource,
                          videoControls = false,
                          videoMuted = true,
                          fullWidth = false,
                      }) {
    const fileType = mediaSource.split(/[#?]/)[0].split(".").pop().trim();
    return (
        <div
            style={{
                ...(fullWidth ? {
                    overflowY: "hidden",
                    maxHeight: "500px",
                } : {
                    width: "320px",
                    height: "500px",
                    display: "flex",
                    objectFit: "contain",
                }),
            }}
        >
            {fileType === "mp4" ? (
                <video
                    style={{
                        ...(fullWidth ? {
                            width: "100%",
                        } : {
                            width: "auto",
                            height: "100%",
                        })

                    }}
                    muted={videoMuted}
                    autoPlay={true}
                    loop={true}
                    controls={videoControls}
                >
                    <source src={mediaSource} />
                </video>
            ) : (
                <img src={mediaSource} alt="" style={{
                    ...(fullWidth ? { width: "100%", height: '600px' } : { width: "auto", height: "100%" }),
                }} />
            )}
        </div>
    );
}

export default MediaContent;

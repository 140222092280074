import { gql } from "@apollo/client";

const GET_STARTED_CAMPAIGNS = gql`
  query getBrandCampaignsStarted($userId: ID!) {
    getBrandCampaignsStarted(userId: $userId) {
      id
      name
      coverPic
      media
      campaignType
      status
      contract
      description
      includesStories
      includesReels
      includesVideos
      includesPictures
      requiresUsageRights
      usageRightsPeriod
      campaignContracts {
        id
        userId
        influencerQuote
        brandQuote
        contractType
        description
        finalFee
        platformFee
      }
    }
  }
`;

export default GET_STARTED_CAMPAIGNS;

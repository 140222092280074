/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import Chart from "react-apexcharts";
import useWindowDimensions from "../CustomHooks/windowDimensions";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import GET_GIFTED_CAMPAIGN from "../../graphql/queries/getGiftedCampaigns";
import GET_PAID_CAMPAIGN from "../../graphql/queries/getPaidCampaigns";
import GET_ALL_CAMPAIGNS from  "../../graphql/queries/getAllCampaigns";
import { useQuery } from "@apollo/client";
import "./style.css"
import CampaignSelectDropdown from "./../sharedComponents/campaignSelectDropdown";
import BrandSelectDropdown from "../sharedComponents/brandSelectDropdown";
import GET_BRANDS from "../../graphql/queries/getBrands";
import PublishButton from '../sharedComponents/publishButton';
import UnpublishButton from "../sharedComponents/unpublishButton";
import { MenuProps, useStyles, options } from "./utils";
import BudgetInput from "../sharedComponents/budgetInput";
import moment from 'moment'
import { getCurrencySymbol } from '../sharedComponents/currencies';

const graphToShow = [
  ["Instagram EMV", "Tiktok EMV", "Earned Media Value Comparison"],
  ["Instagram CPE", "Tiktok CPE", "Cost Per Engagement Comparison"],
  ["Instagram CPC", "Tiktok CPC", "Cost Per Click"],
  [
    "Instagram Engagement",
    "Tiktok Video Views",
    "Tiktok (Views) vs Instagram (Engagement) Comparison",
  ],
];

// Money spent / Number of Click -> Only for Stories
// Money spent / Total Engagement (Total Likes + Total Comments) -> Only for Stories


const colors = [
  ["#008ffb", "#00e396"],
  ["#48dbfb", "#f368e0"],
  ["#32ff7e", "#fff200"],
  ["#48dbfb", "#f368e0"],
];

const shortNum = (number, decimal) => {
  decimal = Math.pow(10, decimal);

  var short = ["k", "m", "b"];

  for (var i = short.length - 1; i >= 0; i--) {
    var size = Math.pow(10, (i + 1) * 3);

    if (size <= number) {
      number = ((number * decimal) / size / decimal).toFixed(1);

      if (number === 1000 && i < short.length - 1) {
        number = 1;
        i++;
      }

      number += short[i];
      break;
    }
  }

  return number;
};

const selectEmvOption = [{id: 1, name: "Total engagement"}, {id: 2, name: "Total views"}]

const MetricsComparison = (props) => {
  const classes = useStyles();
  const { height, width } = useWindowDimensions();
  const [selectedGraph, setSelectedGraph] = useState(0);
  const [myAllCampaigns, setMyAllCampaigns] = useState();
  const [selectOption, setSelectOption] = useState("Total engagement")
  const [queryType, setQueryType] = useState(GET_GIFTED_CAMPAIGN);
  const [allCampaignsData, setAllCampaignsData] = useState([])
  const [data, setData] = useState([]);
  const [showInput, setShowInput] = useState(true);
  const [showSymbol, setShowSymbol] = useState(true)
  const { userRole, chart } = props;
  const [brands, setBrands] = useState([]);
  const currentUserId = localStorage.getItem('currentUser');
  const [selectedBrand, setSelectedBrand] = useState(currentUserId);
  const [selectedCampaignIds, setSelectedCampaignIds] = useState([])
  const [campaignResults, setCampaignResults] = useState([])
  const budgetValue = localStorage.getItem('budget');
  const budgetTypeValue = localStorage.getItem('budgetType');
  const [budget, setBudget] = useState(budgetValue);
  const [budgetType, setBudgetType] = useState(budgetTypeValue);
  const [chartType, setChartType] = useState('area')
  const [tooltipIntersect, setTooltipIntersect] = useState(false)

  useEffect(() => {
    if (selectedGraph == '1'){
      setShowInput(true);
      setShowSymbol(true);
    }

  }, [selectedGraph]);

  const { campaignsData, refetch } = useQuery(queryType, {
    variables: {
      userId: selectedBrand,
      userRole: userRole,
      graphId: chart?.id
    }, onCompleted: (res) => {
      setAllCampaignsData(res);
    }
  });

  const { data: allBrandsData } = useQuery(GET_BRANDS, {
    skip: userRole !== 'Admin',
    onCompleted: (response) => {
      if (userRole === 'Admin') {
        setBrands(response.getBrands);
      }
    },
  });

  function formatResult(result) {
    if (isNaN(result) || !isFinite(result)) {
      return 0;
    } else {
      return result;
    }
  }

  function setGraphOptions(campaigns) {
    if (campaigns.length <= 1) {
      setChartType('bar');
      setTooltipIntersect(true);
    } else {
      setChartType('area')
      setTooltipIntersect(false);
    }
  }

  function processCampaignResults(campaigns) {
    const campaignResultData = {};

    if (campaigns?.length > 0) {
      for (const campaign of campaigns) {
        const key = campaign.name;
        let totalInstaImpressions = 0;
        let totalTiktokImpressions = 0;

        if (!campaignResultData[key]) {
          campaignResultData[key] = {
            instaImpressions: 0,
            tiktokImpressions: 0,
            instaClicks: 0,
            tiktokClicks: 0,
            instaEngagement: 0,
            tiktokEngagement: 0,
            instaCpm: 0,
            tiktokCpm: 0,
            instaLikes: 0,
            instaComments: 0,
            instaReach: 0,
            tiktokLikes: 0,
            tiktokComments: 0,
            tiktokViews: 0,
            instaPlays: 0,
          };
        }

        for (const currentResult of campaign.campaignResults) {
          totalInstaImpressions += currentResult?.instaImpressions || 0;
          totalTiktokImpressions += currentResult?.tiktokPlays || 0;
          campaignResultData[key].instaClicks += currentResult?.instaLinkClicks;
          campaignResultData[key].instaPlays += currentResult?.instaPlays;
          campaignResultData[key].tiktokClicks += currentResult?.tiktokPlays;
          campaignResultData[key].instaLikes += currentResult?.instaLikes
          campaignResultData[key].instaComments += currentResult?.instaComments
          campaignResultData[key].instaReach += currentResult?.instaAccountsReached
          campaignResultData[key].tiktokLikes += currentResult?.tiktokLikes
          campaignResultData[key].tiktokComments += currentResult?.tiktokComments
          campaignResultData[key].tiktokViews += currentResult?.tiktokPlays
          if (selectOption ===  "Total engagement") {
            campaignResultData[key].instaEngagement += currentResult?.instaLikes + currentResult?.instaComments
            campaignResultData[key].tiktokEngagement += currentResult?.tiktokLikes + currentResult?.tiktokComments
          } else if (selectOption === "Total views"){
            campaignResultData[key].instaEngagement += currentResult?.instaAccountsReached
            campaignResultData[key].tiktokEngagement += currentResult?.tiktokPlays
          }
        }

        campaignResultData[key].instaCpm = budget && totalInstaImpressions ? (1000 * (budget / totalInstaImpressions)) : 0;
        campaignResultData[key].tiktokCpm = budget && totalTiktokImpressions ? (1000 * (budget / totalTiktokImpressions)) : 0;
        campaignResultData[key].instaImpressions += totalInstaImpressions;
        campaignResultData[key].tiktokImpressions += totalTiktokImpressions;
        campaignResultData[key].startDate = campaign.startDate;
        campaignResultData[key].endDate = campaign.endDate;

      }
    }

    return campaignResultData;
  }

  useEffect(() => {
    if(allCampaignsData) {
      const campaigns = getCampaigns(allCampaignsData);
      const allCampaigns = campaigns?.map((item) => item.name)
      const instaImpressions = []
      const tiktokImpressions = []
      let instaEngagement = []
      let tiktokEngagement = []
      let instaCpm = []
      let tiktokCpm = []
      let instaClicks = []
      let tiktokClicks = []
      let tiktokViews = []
      let instaData = []
      let campaignResultData = {}
      let plays = []

      setGraphOptions(campaigns)

      campaignResultData = processCampaignResults(campaigns)

      campaigns.map((campaign) => {
        instaImpressions.push([campaign.name, campaignResultData[campaign.name].instaImpressions])
        tiktokImpressions.push([campaign.name, campaignResultData[campaign.name].tiktokImpressions])
        instaClicks.push([campaign.name, campaignResultData[campaign.name].instaClicks])
        tiktokClicks.push([campaign.name, campaignResultData[campaign.name].tiktokClicks])
        instaEngagement.push([campaign.name, campaignResultData[campaign.name].instaEngagement])
        tiktokEngagement.push([campaign.name, campaignResultData[campaign.name].tiktokEngagement])
        instaCpm.push([campaign.name, campaignResultData[campaign.name].instaCpm])
        tiktokCpm.push([campaign.name, campaignResultData[campaign.name].tiktokCpm]);
        instaData.push([campaign.name, campaignResultData[campaign.name].instaComments + campaignResultData[campaign.name].instaLikes])
        tiktokViews.push([campaign.name, campaignResultData[campaign.name].tiktokViews]);
        plays.push([campaign.name, campaignResultData[campaign.name].instaPlays])
      })

      setData([
        [
          processEmvData(instaImpressions, instaEngagement, instaCpm, campaigns),
          processEmvData(tiktokImpressions, tiktokEngagement, tiktokCpm, campaigns)
        ],
        [
          processCpeData(instaEngagement, campaigns),
          processCpeData(tiktokEngagement, campaigns),
        ],
        [
          processCpcData(campaigns, instaClicks),
          processCpcData(campaigns, tiktokClicks)
        ],
        [
          tiktokVsInstsData(instaData, campaigns, plays),
          tiktokVsInstsData(tiktokViews, campaigns, plays),
        ],
      ])
      setMyAllCampaigns(allCampaigns)
      setCampaignResults(campaignResultData)
    }
  }, [allCampaignsData, selectedGraph, budget, selectOption])

  useEffect(() => {
    if (selectedBrand) {
      refetch()
    }
  }, [selectedBrand, refetch])

  const getCampaignResult = (campaignName, campaigns) => {
    let resultData = processCampaignResults(campaigns)

    return resultData[campaignName] || {}
  };

  const buildSocialMediaMetrics = (campaignResult) => {

    return {
      instaLikes: campaignResult?.instaLikes?.toLocaleString(),
      instaComments: campaignResult?.instaComments?.toLocaleString(),
      instaViews: campaignResult?.instaReach?.toLocaleString(),
      instaClicks: campaignResult?.instaClicks?.toLocaleString(),
      tiktokLikes: campaignResult?.tiktokLikes?.toLocaleString(),
      tiktokComments: campaignResult?.tiktokComments?.toLocaleString(),
      tiktokViews: campaignResult?.tiktokViews?.toLocaleString(),
      startDate: campaignResult?.startDate?.toLocaleString(),
      endDate: campaignResult?.endDate?.toLocaleString(),
      instaCpm: campaignResult?.instaCpm?.toLocaleString(),
      tiktokCpm: campaignResult?.tiktokCpm?.toLocaleString(),
    };
  };

  const processEmvData = (impressions, engagementData, cpmValue, campaigns) => {
    return impressions?.map((impression, index) => {
      const campaignResult = getCampaignResult(impression[0], campaigns);
      const engagementValue = engagementData[index][1];

      return {
        x: impression[0],
        y: formatResult(impression[1] * engagementValue * cpmValue[index][1]),
        impression: impression[1].toLocaleString(),
        engagement: engagementValue,
        cpm: cpmValue[index][1],
        ...buildSocialMediaMetrics(campaignResult),
      };
    });
  };

  const processCpeData = (engagements, campaigns) => {
    return engagements?.map((engagement) => {
      const campaignResult = getCampaignResult(engagement[0], campaigns);

      return {
        x: engagement[0],
        y: formatResult(budget / engagement[1]).toFixed(2),
        ...buildSocialMediaMetrics(campaignResult),
      };
    });
  };

  const processCpcData = (campaigns, clicks) => {
    return campaigns.map((campaign, index) => {
      const campaignResult = getCampaignResult(campaign?.name, campaigns);

      return {
        x: campaign.name,
        y: formatResult(budget / clicks?.[index]?.[1]).toFixed(2),//.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
        cost: budget?.toLocaleString(),
        ...buildSocialMediaMetrics(campaignResult),
      };
    });
  };

  const tiktokVsInstsData = (engagements, campaigns, plays) => {
    return engagements?.map((engagement, index) => {
      const campaignResult = getCampaignResult(engagement[0], campaigns);

      return {
        x: engagement[0],
        y: engagement[1],
        plays: plays[index][1].toLocaleString(),
        ...buildSocialMediaMetrics(campaignResult),
      };
    });
  };

  const getCampaigns = (data) => {
    if (!data) return [];

    if (data.getGiftedCampaigns) {
      return (data.getGiftedCampaigns);
    } else if (data.getPaidCampaigns) {
      return (data.getPaidCampaigns);
    } else if (data.getAllCampaigns) {
      return (data.getAllCampaigns);
    } else {
      return [];
    }
  }

  const graphType = [
    { id: 0, name: "EMV Graph" },
    { id: 1, name: "CPE Graph" },
    { id: 2, name: "CPC Graph" },
    { id: 3, name: "Tiktok vs Instagram Graph" },
  ];

  const handleChange = (event) => {
    const option = event.target.value
    setSelectedGraph(option);

    if(option === 0) {
      setQueryType(GET_GIFTED_CAMPAIGN)
      setShowInput(true)
      setShowSymbol(true)
    }
    else if(option === '1') {
      setQueryType(GET_PAID_CAMPAIGN)
      setShowInput(true)
      setShowSymbol(true)
    }
    else if(option === 2) {
      setQueryType(GET_ALL_CAMPAIGNS)
      setShowInput(true)
      setShowSymbol(true)
    }
    else if(option === 3) {
      setQueryType(GET_ALL_CAMPAIGNS)
      setShowInput(false)
      setShowSymbol(false)
    }
  };

  let series = []

  if(data?.length > 0) {
    series = [
      {
        name: graphToShow[selectedGraph][0],
        data: data[selectedGraph][0],
      },
      {
        name: graphToShow[selectedGraph][1],
        data: data[selectedGraph][1],
      },
    ];
  }

  const options = {
    chart: {
      foreColor: "#fff",
      background: "#000",
    },
    legend: {
      fontSize: "16px",
      position: "right",
      fontSize: "16px",
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          if (showSymbol) {
            return "£ " + shortNum(value.toFixed(2), 0);
          } else {
            return shortNum(value, 0);
          }
        },
        style: {
          fontSize: "16px",
        }
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val?.toLocaleString();
      },
    },
  };

  const extendedOptions = {
    xaxis: {
      categories: myAllCampaigns,
    },
    fill: {
      type: "solid",
      colors: colors[selectedGraph],
    },
    stroke: {
      colors: colors[selectedGraph],
    },
    tooltip: {
      enabled: true,
      x: {
        show: true,
      },
      followCursor: true,
      shared: false,
      theme: 'dark',
      intersect: tooltipIntersect,
      fillSeriesColor: true,
      custom: function({series, seriesIndex, dataPointIndex, w}) {
        var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
        return tooltipData(data)
      }
    }
  };

  const formatDate = (date) => {
    return moment.utc(date).format('DD/MM/YYYY')
  }
  const tooltipData = (tooltipData) => {
    let tooltipContent =
    '<div class="tooltip">' +
      '<div class="heading-tooltip">' +
        '<div style="">' + '<strong>' + tooltipData?.x + '</strong>' + '</div>' +
      '</div>' +
      '<div class="data-tooltip-main">';

    if(selectedGraph === 0) {
      tooltipContent += '<div style="data-value">EMV:  ' + '<strong>' + tooltipData?.y?.toLocaleString() + '</strong>' + '</div>' +
      '<div style="data-value">Impressions:  ' + '<strong>' + tooltipData?.impression + '</strong>' + '</div>' +
      '<div style="data-value">CPM:  ' + '<strong>' + tooltipData?.cpm + '</strong>' + '</div>' +
      '<div style="data-value">Budget:  ' + '<strong>' + getCurrencySymbol(budgetType) + budget?.toLocaleString() + '</strong>' + '</div>';
    } else if(selectedGraph == 1) {
      tooltipContent += '<div style="data-value">CPE:  ' + '<strong>' + tooltipData?.y?.toLocaleString() + '</strong>' + '</div>' +
      '<div style="data-value">Budget:  ' + '<strong>' + getCurrencySymbol(budgetType) + budget?.toLocaleString() + '</strong>' + '</div>';
    } else if(selectedGraph == 2) {
      tooltipContent += '<div style="data-value">CPC:  ' + '<strong>' + tooltipData?.y?.toLocaleString()+ '</strong>' + '</div>' +
      '<div style="data-value">Budget:  ' + '<strong>' + getCurrencySymbol(budgetType) + tooltipData?.cost + '</strong>' + '</div>' +
      '<div style="data-value">Insta Clicks:  ' + '<strong>' + tooltipData?.instaClicks + '</strong>' + '</div>';
    } else if(selectedGraph == 3) {
      tooltipContent += '<div style="data-value">Views:  ' + '<strong>' + tooltipData?.plays + '</strong>' + '</div>';
    }

    tooltipContent +=
        '<div style="data-value">Start Date:  ' + '<strong>' + formatDate(tooltipData?.startDate) + '</strong>' + '</div>' +
        '<div style="data-value">End Date:  ' + '<strong>' + formatDate(tooltipData?.endDate) + '</strong>' + '</div>' +
        '<div style="data-value">Insta Likes:  ' + '<strong>' + tooltipData?.instaLikes  + '</strong>' + '</div>' +
        '<div style="data-value">Insta Comments:  ' + '<strong>' + tooltipData?.instaComments + '</strong>' + '</div>' +
        '<div style="data-value">Insta Reach:  ' + '<strong>' + tooltipData?.instaViews + '</strong>' + '</div>' +
        '<div style="data-value">Tiktok Likes:  ' + '<strong>' + tooltipData?.tiktokLikes + '</strong>' + '</div>' +
        '<div style="data-value">Tiktok Comments:  ' + '<strong>' + tooltipData?.tiktokComments + '</strong>' + '</div>' +
        '<div style="data-value">Tiktok Views:  ' + '<strong>' + tooltipData?.tiktokViews + '</strong>' + '</div>' +
      '</div>' +
    '</div>';

    return tooltipContent;
  };

  const handleOptionChange = (event) => {
    setSelectOption(event.target.value)
  }

  const handleSelectionChange = (selectedCampaigns) => {
    let checkedCampaigns = getCampaigns(allCampaignsData)?.filter((campaign) => selectedCampaigns.includes(campaign.name));
    setSelectedCampaignIds(checkedCampaigns.map((campaign => campaign.id)));
  };

  const handleBrandChange = (event) => {
    const value = event.target.value;
    setSelectedBrand(value);
  }

  const handleBudgetChange = (value) => {
    setBudget(value);
  };

  const handleBudgetTypeChange = (value) => {
    setBudgetType(value)
  }

  return (
    <Grid item xs container justifyContent="center">
      <Grid item xs={11} container justifyContent="space-between">
        <Typography variant="h5" style={{padding: '2px', marginLeft: '3px'}}> {graphToShow[selectedGraph][2]}</Typography>
        <Box sx={{ minWidth: 200 }}>
          <FormControl className={classes.formControl}>
            {userRole == "Admin" && (
              <BrandSelectDropdown
                labelId="brand-select-label"
                brands={brands}
                selectedBrand={selectedBrand}
                handleChange={handleBrandChange}
                />
              )
            }
            <div style={{display: "flex", alignItems: 'center', marginTop: 10}}>
              {userRole == "Admin" && (
                <CampaignSelectDropdown
                  campaigns={getCampaigns(allCampaignsData)}
                  onSelectionChange={handleSelectionChange}
                  classes={classes}
                  MenuProps={MenuProps}
                />)
              }
              <div style={{justifyContent: 'space-between',  display: 'flex' }}>
                <div style={{ flex: 1, margin: 5 }}>
                  <PublishButton
                    graphId={chart?.id}
                    userId={selectedBrand}
                    campaignIds={selectedCampaignIds}
                    userRole={userRole}
                  />
                </div>
                <div style={{flex: 1, margin: 5}}>
                  <UnpublishButton
                    graphId={chart?.id}
                    userId={selectedBrand}
                    campaignIds={selectedCampaignIds}
                    userRole={userRole}
                  />
                </div>
              </div>
            </div>
          </FormControl>
        </Box>
        <Box sx={{ minWidth: 200 }}>
          <FormControl fullWidth>
            <InputLabel
              id="demo-simple-select-label"
              sx={{ color: "#FFF", fontSize: "1rem !important" }}
            >
              Comparison
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={graphType[selectedGraph].id}
              label="Comparison Type"
              variant="standard"
              sx={{
                color: "#FFF",
                background: "#FFA3C7",
                borderRadius: 1,
                padding: 1,
              }}
              onChange={handleChange}
            >
              {graphType?.map((campaign) => (
                <MenuItem value={campaign.id}>{campaign.name}</MenuItem>
              ))}
            </Select>
            {showInput &&
              <div>
                <BudgetInput onBudgetChange={handleBudgetChange} onBudgetTypeChange={handleBudgetTypeChange} />
                <Select
                  labelId="demo-simple-select1-label"
                  id="demo-simple-select1"
                  label="Campaign"
                  variant="standard"
                  value={selectOption}
                  sx={{
                    color: "#FFF",
                    background: "#FFA3C7",
                    borderRadius: 1,
                    padding: 1,
                    mt: "18px"
                  }}
                  onChange={handleOptionChange}
                >
                  {selectEmvOption?.map((option) => (
                    <MenuItem value={option.name}>{option.name}</MenuItem>
                  ))}
                </Select>
              </div>
            }
          </FormControl>
        </Box>
      </Grid>
      <Chart
        options={{
          ...options,
          ...extendedOptions,
        }}
        series={series}
        type={chartType}
        height={height / 1.6}
        width={width - 80}
      />
    </Grid>
  );
};

export default MetricsComparison;

import { gql } from "@apollo/client";

const GET_INFLUENCERS_WITH_TASKS = gql`
  query getInfluencersWithTasks($campaignId: ID!) {
    getInfluencersWithTasks(campaignId: $campaignId) {
      id
      influencer {
        id
        image
        name
      }
      campaignTasks {
        id
        campaignContractId
        description
        imageSubmissions
        status
        videoSubmissions
        updatedAt
        createdAt
        dueDate
        taskType
        taskMedia
      }
    }
  }
`;

export default GET_INFLUENCERS_WITH_TASKS;

import { createStyles, makeStyles } from "@mui/styles";

const styles = makeStyles((theme) =>
  createStyles({
    campaignDetailBanner: {
      borderRadius: 10,
      padding: "4px 8px 10px 8px",
      margin: "auto",
      backgroundColor: "#fff",
      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
    },
    coverImage: {
      borderRadius: 20,
      maxWidth: "100%",
    },
  })
);

export default styles;

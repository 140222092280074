import * as React from "react";
import { Button } from "@mui/material";
import { useState } from "react";
import { makeStyles } from "@mui/styles";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const DownloadContract = ({campaign, contract}) => {
  const fetchFileContent = async (url) => {
    const response = await fetch(url);
    const content = await response.text();
    return content;
};

const downloadAsPDF = async (content, filename) => {
  const element = document.createElement('div');
  element.innerHTML = content;
  document.body.appendChild(element);

  const canvas = await html2canvas(element, {
    scale: 2,
  });

  const pdf = new jsPDF('p', 'mm', 'a4');
  const imgData = canvas.toDataURL('image/png');
  const imgProps = pdf.getImageProperties(imgData);
  const pdfWidth = pdf.internal.pageSize.getWidth();
  const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

  pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);

  pdf.save(filename);

  document.body.removeChild(element);
};

  function getInfluencerContract(contracts) {
    let contract = null; 

    contracts.some((item) => {
      if (item.userId.toString() === localStorage.getItem("currentUser").toString()) {
        contract = item.customContract;
        return true; // Break the loop
      }
      return false;
    });

    return contract;
  }


  const handleDownload = async () =>{
    let combinedContent = await fetchFileContent( "https://authentic-pps.s3.eu-west-2.amazonaws.com/authentic_default_contract.txt");
    
    if(campaign.contract){
      const content = await fetchFileContent(campaign.contract);
      combinedContent += `<h1>Brand Additional Clause</h1><div>${content}</div><br/>`
    }

    if(contract.customContract){
      const influ_contract = getInfluencerContract(contract.customContract)
      if(influ_contract){
        const content = await fetchFileContent(influ_contract);
        combinedContent += `<h1>Influencer Additional Clause</h1><div>${content}</div><br/>`
      }
    }
    downloadAsPDF(combinedContent, 'download_contract.pdf');
  } 

  return (
    <Button variant="contained" style={{marginRight: "15px"}} onClick={handleDownload}>
      Download Contract
    </Button>
  );
};

export default DownloadContract;

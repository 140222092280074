import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Button,
  Grid,
  ListItem,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
} from "@mui/material";
import useStyles from "./styles";
import TIKTOK_ICON from "../../assets/icon/Tiktok.png";
import INSTAGRAM_ICON from "../../assets/icon/Instagram.png";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import { withStyles } from "@mui/styles";
import "pure-react-carousel/dist/react-carousel.es.css";
import { useQuery, useLazyQuery } from "@apollo/client";
import GET_INSTAGRAM_STATS from "../../graphql/queries/getInstagramStats";
import GET_TIKTOK_STATS from "../../graphql/queries/getTiktokStats";
import InfluencerPopup from "../common-components/influencerPopup";
import GET_MY_CAMPAIGNS from "../../graphql/queries/getMyCampaigns";

const PinkButton = withStyles({
  root: {
    marginLeft: "10px !important",
    backgroundColor: "rgba(239, 46, 131, 1) !important",
    color: "#fff",
    "&:hover": {
      backgroundColor: "rgba(239, 46, 131, 0.5) !important",
    },
  },
})(Button);

const filters = [
  { value: "", name: "None" },
  { value: "following-asc", name: "Followers (↑)" },
  { value: "following-desc", name: "Followers (↓)" },
  { value: "engagement-asc", name: "Engagement (↑)" },
  { value: "engagement-desc", name: "Engagement (↓)" },
  { value: "reach-asc", name: "Reach / Views (↑)" },
  { value: "reach-desc", name: "Reach / Views (↓)" },
];

const countryFilterList = [
  { value: "", name: "None" },
  { value: "England", name: "England" },
  { value: "Northern Ireland", name: "Northern Ireland" },
  { value: "Scotland", name: "Scotland" },
  { value: "Wales", name: "Wales" },
  { value: "Others", name: "Others" },
];

export default function Metrics() {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [media, setMedia] = useState("Instagram");
  const [instaMetrics, setInstaMetrics] = useState([]);
  const [tiktokMetrics, setTiktokMetrics] = useState([]);
  const [influencerToShow, setInfluencerToShow] = useState(null);
  const [statsFilter, setStatsFilter] = useState("");
  const [countryFilter, setCountryFilter] = useState("");
  const [selectedCampaign, setSelectedCampaign] = useState({});

  const [fetchInstaStats] = useLazyQuery(GET_INSTAGRAM_STATS, {
    onCompleted: (res) => {
      setInstaMetrics(res.getInstagramStats);
    },
  });
  const [fetchTiktokStats] = useLazyQuery(GET_TIKTOK_STATS, {
    onCompleted: (res) => {
      setTiktokMetrics(res.getTiktokStats);
    },
  });
  const { data: campaignList } = useQuery(GET_MY_CAMPAIGNS, {
    variables: {
      userId: localStorage.getItem("currentUser"),
    },
  });

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleStatsFilterChange = (event) => {
    setStatsFilter(event.target.value);
  };

  const handleCountryFilterChange = (event) => {
    setCountryFilter(event.target.value);
  };

  const handleGalleryButtonClick = (id) => {
    setInfluencerToShow(id);
  };
  useEffect(() => {
    if (media === "Instagram") {
      fetchInstaStats({
        variables: { orderBy: statsFilter, countrySort: countryFilter },
      });
    } else {
      fetchTiktokStats({
        variables: { orderBy: statsFilter, countrySort: countryFilter },
      });
    }
    //eslint-disable-next-line
  }, [media, statsFilter, countryFilter]);

  return (
    <Grid
      item
      xs={12}
      container
      className={classes.mainGrid}
      justifyContent="center"
      sx={{marginLeft: "80px"}}
    >
      <Box className={classes.banner}/>
      <Stack
        direction="row"
        sx={{
          flexDirection: "row !important",
          marginTop: "10px",
          marginRight: "10px",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "rgba(239, 46, 131, 0.3)",
          paddingY: 1,
          paddingX: 2,
          borderRadius: 5,
        }}
      >
        <img
          src={media === "Instagram" ? INSTAGRAM_ICON : TIKTOK_ICON}
          width="50"
          alt="insta-logo"
        />
        <Typography
          variant="h5"
          sx={{
            marginLeft: 9,
            margin: "auto",
          }}
        >
          {media} Metrics
        </Typography>
      </Stack>
      <Grid item xs container justifyContent="end" sx={{ marginRight: 9 }}>
        <Box sx={{ minWidth: 300, marginRight: 2 }}>
          <FormControl fullWidth variant="standard">
            <InputLabel
              id="select-campaign-label"
              sx={{
                color: "rgba(239, 46, 131, 1)",
                fontSize: "1.2rem !important",
              }}
            >
              Select Campaign to Invite
            </InputLabel>
            <Select
              labelId="select-campaign-label"
              id="select-campaign"
              value={selectedCampaign}
              label="Campaign"
              variant="standard"
              onChange={(e) => setSelectedCampaign(e.target.value)}
            >
              {campaignList?.getMyCampaigns.map(
                (filter) =>
                  filter.status === "Approved" && (
                    <MenuItem value={filter} key={"campaign-" + filter.id}>
                      {filter.name}
                    </MenuItem>
                  )
              )}
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ minWidth: 200, marginRight: 2 }}>
          <FormControl fullWidth>
            <InputLabel id="filter-by-country-label">
              Filter By Country
            </InputLabel>
            <Select
              labelId="filter-by-country-label"
              id="filter-by-country"
              value={countryFilter}
              label="Campaign"
              variant="standard"
              onChange={handleCountryFilterChange}
            >
              {countryFilterList.map((filter) => (
                <MenuItem value={filter.value} key={filter.value}>
                  {filter.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ minWidth: 200 }}>
          <FormControl fullWidth>
            <InputLabel id="Filter-By-Stats-label">Filter By Stats</InputLabel>
            <Select
              labelId="Filter-By-Stats-label"
              id="Filter-By-Stats"
              value={statsFilter}
              label="Filter By Stats"
              variant="standard"
              onChange={handleStatsFilterChange}
            >
              {filters.map((filter) => (
                <MenuItem value={filter.value} map={filter.value}>
                  {filter.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <PinkButton
          variant="contained"
          onClick={() => setMedia("Instagram")}
          sx={{ display: media === "Instagram" ? "none" : "", height: 50 }}
        >
          View Instagram
        </PinkButton>
        <PinkButton
          variant="contained"
          onClick={() => setMedia("TikTok")}
          sx={{ display: media === "TikTok" ? "none" : "", height: 50 }}
        >
          View Tiktok
        </PinkButton>
      </Grid>
      {instaMetrics.length < 1 && (
        <Grid
          item
          xs={12}
          container
          className={classes.mainGrid}
          sx={{ marginTop: 5 }}
          justifyContent="center"
        >
          <Typography variant="h5" sx={{ marginLeft: 9 }}>
            No Influencers found, try some other filter
          </Typography>
        </Grid>
      )}
      <Grid
        item
        xs={12}
        container
        sx={{ paddingTop: 5 }}
        justifyContent="center"
      >
        {media === "Instagram"
          ? instaMetrics?.map((data, index) => (
              <Accordion
                key={"influencer-metric" + data?.id}
                expanded={expanded === "panel" + data?.id}
                onChange={handleChange("panel" + data?.id)}
                sx={{
                  width: "100%",
                  backgroundColor:
                    index % 2 === 0 ? "#FFA3C7" : "",
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={data?.user?.name + "-metrics"}
                  id={data?.name + "-metrics"}
                  sx={{ alignItems: "center", backgroundColor: "transparent" }}
                >
                  <List
                    dense
                    sx={{
                      width: "90%",
                      backgroundColor: "transparent",
                      display: "inline-flex",
                      alignItems: "center",
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar
                        alt=""
                        src={
                          data?.user?.image ||
                          "https://mir-s3-cdn-cf.behance.net/user/230/110659178842.5c1b604bb2cea.jpg"
                        }
                      />
                    </ListItemAvatar>
                    <ListItemText
                      id={data?.id}
                      primary={data?.user?.name}
                      primaryTypographyProps={{ fontWeight: 700 }}
                      sx={{ width: 200 }}
                    />
                    <ListItemText
                      id={data?.id}
                      primary={"Total Following: "}
                      secondary={data?.following || "-"}
                      primaryTypographyProps={{
                        textAlign: "center",
                        fontWeight: 700,
                      }}
                      secondaryTypographyProps={{
                        textAlign: "center",
                      }}
                      sx={{ width: 180 }}
                    />
                    <ListItemText
                      id={data?.id}
                      primary={"Engagement: "}
                      secondary={data?.accountsEngaged || "-"}
                      primaryTypographyProps={{
                        textAlign: "center",
                        fontWeight: 700,
                      }}
                      secondaryTypographyProps={{
                        textAlign: "center",
                      }}
                      sx={{ width: 180 }}
                    />
                    <ListItemText
                      id={data?.id}
                      primary={"Top Country: "}
                      secondary={data?.topCountry || "-"}
                      primaryTypographyProps={{
                        textAlign: "center",
                        fontWeight: 700,
                      }}
                      secondaryTypographyProps={{
                        textAlign: "center",
                      }}
                      sx={{ width: 180 }}
                    />
                    <ListItemText
                      id={data?.id}
                      primary={"Top City: "}
                      secondary={data?.topCity || "-"}
                      primaryTypographyProps={{
                        textAlign: "center",
                        fontWeight: 700,
                      }}
                      secondaryTypographyProps={{
                        textAlign: "center",
                      }}
                      sx={{ width: 180 }}
                    />
                  </List>
                </AccordionSummary>
                <AccordionDetails>
                  <List
                    dense
                    sx={{
                      width: "90%",
                      bgcolor: "background.paper",
                      display: "inline-flex",
                      alignItems: "center",
                    }}
                  >
                    <ListItemText
                      id={data?.id}
                      primary={"Account Reach"}
                      secondary={data?.accountsReached || "-"}
                      primaryTypographyProps={{
                        textAlign: "center",
                        fontWeight: 700,
                      }}
                      secondaryTypographyProps={{
                        textAlign: "center",
                      }}
                      sx={{ width: 180 }}
                    />
                    <ListItemText
                      id={data?.id}
                      primary={"Female Ratio"}
                      secondary={data?.femaleRatio + "%" || "-"}
                      primaryTypographyProps={{
                        textAlign: "center",
                        fontWeight: 700,
                      }}
                      secondaryTypographyProps={{
                        textAlign: "center",
                      }}
                      sx={{ width: 180 }}
                    />
                    <ListItemText
                      id={data?.id}
                      primary={"Male Ratio"}
                      secondary={data?.maleRatio + "%" || "-"}
                      primaryTypographyProps={{
                        textAlign: "center",
                        fontWeight: 700,
                      }}
                      secondaryTypographyProps={{
                        textAlign: "center",
                      }}
                      sx={{ width: 180 }}
                    />
                    <ListItemText
                      id={data?.id}
                      primary={"Top Age Range"}
                      secondary={data?.topAgeRange || "-"}
                      primaryTypographyProps={{
                        textAlign: "center",
                        fontWeight: 700,
                      }}
                      secondaryTypographyProps={{
                        textAlign: "center",
                      }}
                      sx={{ width: 180 }}
                    />
                    <ListItem sx={{ width: 180 }}>
                      <PinkButton
                        variant="contained"
                        onClick={() => handleGalleryButtonClick(data?.user.id)}
                      >
                        View Gallery
                      </PinkButton>
                    </ListItem>
                  </List>
                </AccordionDetails>
              </Accordion>
            ))
          : tiktokMetrics?.map((data, index) => (
              <Accordion
                key={"influencer-metric" + data?.id}
                expanded={expanded === "panel" + data?.id}
                onChange={handleChange("panel" + data?.id)}
                sx={{
                  width: "100%",
                  backgroundColor:
                    index % 2 === 0 ? "#FFA3C7" : "",
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={data?.user?.name + "-metrics"}
                  id={data?.user?.name + "-metrics"}
                  sx={{ alignItems: "center" }}
                >
                  <List
                    dense
                    sx={{
                      width: "90%",
                      backgroundColor: "transparent",
                      display: "inline-flex",
                      alignItems: "center",
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar
                        alt=""
                        src={
                          data?.user?.image ||
                          "https://mir-s3-cdn-cf.behance.net/user/230/110659178842.5c1b604bb2cea.jpg"
                        }
                      />
                    </ListItemAvatar>
                    <ListItemText
                      id={data?.id}
                      primary={data?.user?.name}
                      primaryTypographyProps={{ fontWeight: 700 }}
                      sx={{ width: 200 }}
                    />
                    <ListItemText
                      id={data?.id}
                      primary={"Total Following: "}
                      secondary={data?.following || "-"}
                      primaryTypographyProps={{
                        textAlign: "center",
                        fontWeight: 700,
                      }}
                      secondaryTypographyProps={{
                        textAlign: "center",
                      }}
                      sx={{ width: 180 }}
                    />
                    <ListItemText
                      id={data?.id}
                      primary={"Engagement: "}
                      secondary={data?.accountsEngaged || "-"}
                      primaryTypographyProps={{
                        textAlign: "center",
                        fontWeight: 700,
                      }}
                      secondaryTypographyProps={{
                        textAlign: "center",
                      }}
                      sx={{ width: 180 }}
                    />
                    <ListItemText
                      id={data?.id}
                      primary={"Top Country: "}
                      secondary={data?.topCountry || "-"}
                      primaryTypographyProps={{
                        textAlign: "center",
                        fontWeight: 700,
                      }}
                      secondaryTypographyProps={{
                        textAlign: "center",
                      }}
                      sx={{ width: 180 }}
                    />
                    <ListItemText
                      id={data?.id}
                      primary={"Top City: "}
                      secondary={data?.topCity || "-"}
                      primaryTypographyProps={{
                        textAlign: "center",
                        fontWeight: 700,
                      }}
                      secondaryTypographyProps={{
                        textAlign: "center",
                      }}
                      sx={{ width: 180 }}
                    />
                  </List>
                </AccordionSummary>
                <AccordionDetails>
                  <List
                    dense
                    sx={{
                      width: "90%",
                      bgcolor: "background.paper",
                      display: "inline-flex",
                      alignItems: "center",
                    }}
                  >
                    <ListItemText
                      id={data?.id}
                      primary={"Video Views"}
                      secondary={data?.videoViews || "-"}
                      primaryTypographyProps={{
                        textAlign: "center",
                        fontWeight: 700,
                      }}
                      secondaryTypographyProps={{
                        textAlign: "center",
                      }}
                      sx={{ width: 180 }}
                    />
                    <ListItemText
                      id={data?.id}
                      primary={"Female Ratio"}
                      secondary={data?.femaleRatio + "%" || "-"}
                      primaryTypographyProps={{
                        textAlign: "center",
                        fontWeight: 700,
                      }}
                      secondaryTypographyProps={{
                        textAlign: "center",
                      }}
                      sx={{ width: 180 }}
                    />
                    <ListItemText
                      id={data?.id}
                      primary={"Male Ratio"}
                      secondary={data?.maleRatio + "%" || "-"}
                      primaryTypographyProps={{
                        textAlign: "center",
                        fontWeight: 700,
                      }}
                      secondaryTypographyProps={{
                        textAlign: "center",
                      }}
                      sx={{ width: 180 }}
                    />
                    <ListItemText
                      id={data?.id}
                      primary={"Top Age Range"}
                      secondary={data?.topAgeRange || "-"}
                      primaryTypographyProps={{
                        textAlign: "center",
                        fontWeight: 700,
                      }}
                      secondaryTypographyProps={{
                        textAlign: "center",
                      }}
                      sx={{ width: 180 }}
                    />
                    <ListItem sx={{ width: 180 }}>
                      <PinkButton
                        variant="contained"
                        onClick={() => handleGalleryButtonClick(data?.user.id)}
                      >
                        View Gallery
                      </PinkButton>
                    </ListItem>
                  </List>
                </AccordionDetails>
              </Accordion>
            ))}
      </Grid>
      {influencerToShow && (
        <InfluencerPopup
          influencerId={influencerToShow}
          nextInfluencer={() => console.log("I will do something")}
          closePopup={() => setInfluencerToShow(null)}
          campaignId={selectedCampaign.id}
        />
      )}
    </Grid>
  );
}

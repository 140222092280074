import PropTypes from "prop-types";
import {
  Card,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";

import { useQuery } from "@apollo/client";
import PROFILE_CHECKLIST from "../../../graphql/queries/profileChecklist";
// ----------------------------------------------------------------------

Checklist.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  list: PropTypes.array.isRequired,
};

export default function Checklist({ title, subheader, list, ...other }) {
  const { data } = useQuery(PROFILE_CHECKLIST, {
    variables: {
      userId: localStorage.getItem("currentUser"),
    },
  });
  const dashboardKey = {
    instagram_insights: data?.brandDashboardInsights.instagram_insights,
    tiktok_insights: data?.brandDashboardInsights.tiktok_insights,
    gallery: data?.brandDashboardInsights.gallery,
    campaigns: data?.brandDashboardInsights.campaigns,
  };

  if (data === undefined) return <></>;
  return (
    <Card {...other} sx={{ height: 80 }}>
      <Typography variant="h5" sx={{ width: "100%" }} align="center" mt={1}>
        To Do:
      </Typography>
      <Grid
        item
        xs={12}
        container
        justifyContent="space-around"
        alignItems="center"
      >
        {list.map((task) => (
          <TaskItem key={task.id} task={task} status={dashboardKey[task.id]} />
        ))}
      </Grid>
    </Card>
  );
}

// ----------------------------------------------------------------------

TaskItem.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  task: PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
  }),
};
function TaskItem({ task, status }) {
  return (
    <FormControlLabel
      control={<Checkbox checked={status} />}
      label={task.label}
      sx={{
        marginLeft: "5px",
        pointerEvents: "none",
      }}
    />
  );
}

import { gql } from "@apollo/client";

const GET_INTERESTED_INFLUENCERS = gql`
  query getInterestedInfluencers($campaignId: ID!) {
    getInterestedInfluencers(campaignId: $campaignId) {
      id
      influencerQuote
      influencerQuoteType
      brandQuote
      brandQuoteType
      currentFlow
      currentNegotiator
      finalFee
      platformFee
      description
      contractStatus
      customContract
      influencer {
        id
        name
        image
      }
      brandName
      adminName
      influencerName
      brandSignature
      adminSignature
      influencerSignature
      brandDate
      adminDate
      influencerDate
    }
  }
`;

export default GET_INTERESTED_INFLUENCERS;

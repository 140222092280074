import { Grid, Stack, Typography, Paper } from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";
import MediaContent from "./mediaContent";
import { CarouselProvider, Slider, Slide } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import { makeStyles } from "@mui/styles";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const useStyles = makeStyles({
  cardUpdated: {
    '@media (min-width: 1200px)': {
      '&': {
        maxWidth: '24% !important',
        flexBasis: '24% !important',
      },
    },
  },
});

const MiniInfluencerCard = (props) => {
  const classes = useStyles();

  const {
    city,
    country,
    image,
    instagramStat,
    name,
    tiktokStat,
    userGalleries,
  } = props;

  debugger
  return (
      <Grid
          item
          xs={12}
          className={classes.cardUpdated}
          md={5}
          lg={3}
          mb={2}
          sx = {{ padding: "0 !important", border: "1px solid rgba(0,0,0,0.3)", overflowY: "hidden", borderRadius: "15px" }}
      >
        <Stack
            direction="row"
            spacing={2}
            sx={{ margin: "10px" }}
        >
          <Item>
            <img
                src={
                    image ||
                    "https://mir-s3-cdn-cf.behance.net/user/230/110659178842.5c1b604bb2cea.jpg"
                }
                alt="avatar"
                width="80px"
            />
          </Item>
          <Stack>
            <Typography variant="h6">{name}</Typography>
            <Typography variant="body1">
              {country},{city}
            </Typography>
            <Stack direction="row">
              <Typography variant="caption">
                <b>Instagram Followers:</b>
                {` ${instagramStat.following || "n/a"}`}
              </Typography>
            </Stack>
            <Stack direction="row">
              <Typography variant="caption">
                <b>TikTok Followers:</b>
                {` ${tiktokStat.following || "n/a"}`}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <Stack sx={{ width: "100%", maxHeight: "500px", height: "100%", marginTop: 1 }}>
          <CarouselProvider
              isPlaying={true}
              naturalSlideWidth={320}
              naturalSlideHeight={500}
              totalSlides={userGalleries?.length}
              style={{ height: "100%", minHeight: "500px" }}
          >
            <Slider style={{ maxHeight:'500px', minHeight:'500px' }}>
              {userGalleries.map((item, index) => {
                return (
                    <Slide index={index} key={item.id}>
                      <MediaContent mediaSource={item.item} fullWidth={true} />
                    </Slide>
                );
              })}
            </Slider>
          </CarouselProvider>
        </Stack>
      </Grid>
  );
};

export default MiniInfluencerCard;

import React, { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import useStyles from "./styles";
import CampaignComparisonChart from "./campaignComparisonChart";
import InfluencerPerformanceChart from "./influencerPerformanceChart";
import CampaignCostBreakdown from "./campaignCostBreakdown";
import MetricsComparison from "./metricsComparison";
import CpeComparisonChart from "./cpeComparisonChart";
import CpcComparisonChart from "./cpcComparisonChart";
import CerComparisonChart from "./cerComparisonChart";
import RoiChart from "./roiChart";
import { useQuery } from "@apollo/client";
import GET_GRAPHS from "../../graphql/queries/getGraphs";
import GET_BRAND_GRAPHS from "../../graphql/queries/getBrandGraphs";
import metricsGraphImage from "./../../assets/metrics-graph-image.png";
import cpeGraphImage from "./../../assets/cpe-comparison.png";
import cpcGraphImage from "./../../assets/cpc-comparison.png";
import cerGraphImage from "./../../assets/cer-comparison.png";
import campaignComparisonImage from "./../../assets/campaign-comparison.png";
import roiChartImage from "./../../assets/roi-chart.png";
import ChartWrapper from "./chartWrapper";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const Stats = () => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [graphs, setGraphs] = useState([]);
  const [brandGraphs, setBrandGraphs] = useState([]);
  let userRole = localStorage.getItem("userRole");
  const currentUserId = localStorage.getItem("currentUser");
  let metricGraph = "";
  let cerGraph = "";
  let cpcGraph = "";
  let cpeGraph = "";
  let campaignComparisonGraph = "";
  let roiChart = "";
  let campaignCostGraph = "";
  let influencerGraph = "";

  const { data: allGraphsData } = useQuery(GET_GRAPHS, {
    onCompleted: (response) => {
      setGraphs(response.getGraphs);
    },
  });

  const { allBrandGraphsData } = useQuery(GET_BRAND_GRAPHS, {
    variables: {
      userId: currentUserId,
    },
    onCompleted: (res) => {
      let graphData = res.getBrandGraphs.reduce((acc, record) => {
        const key = record?.dashboardGraph?.name;

        if (!acc[key]) {
          acc[key] = {
            campaingId: record?.campaignId,
            published: false,
          };
        }
        acc[key].published = acc[key].published || record.published;

        return acc;
      }, {});
      graphData["Influencer Chart"] = { campaingId: 0, published: true };
      graphData["Campaign Cost Breakdown Chart"] = {
        campaingId: 0,
        published: true,
      };
      setBrandGraphs(graphData);
    },
  });

  if (graphs) {
    influencerGraph = graphs?.find(
      (graph) => graph?.name === "Influencer Chart"
    );
    campaignCostGraph = graphs?.find(
      (graph) => graph?.name === "Campaign Cost Breakdown Chart"
    );
    metricGraph = graphs?.find((graph) => graph?.name === "Metrics Comparison");
    cerGraph = graphs?.find((graph) => graph?.name === "Cer Comparison Chart");
    cpcGraph = graphs?.find((graph) => graph?.name === "Cpc Comparison Chart");
    cpeGraph = graphs?.find((graph) => graph?.name === "Cpe Comparison Chart");
    campaignComparisonGraph = graphs?.find(
      (graph) => graph?.name === "Campaign Comparison Chart"
    );
    roiChart = graphs?.find((graph) => graph?.name === "Roi Chart");
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Grid item xs={12} container className={classes.mainGrid}>
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              color: "#fff",
              paddingLeft: 3,
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="chart tabs"
              variant="scrollable" // Make the tabs scrollable
              scrollButtons="auto" // Show scroll buttons when needed
            >
              {/* <Tab label="Campaign Cost Breakdown" className={classes.tab} /> */}
              {/* <Tab label="Influencer Graph" className={classes.tab} /> */}
              <Tab label="Campaign Comparison" className={classes.tab} />
              <Tab label="Metrics Comparison" className={classes.tab} />
              <Tab label="CPE Comparison" className={classes.tab} />
              <Tab label="CPC Comparison" className={classes.tab} />
              <Tab label="CER Comparison" className={classes.tab} />
              <Tab label="Roi Chart" className={classes.tab} />
            </Tabs>
          </Box>
          {/* <TabPanel value={value} index={0}>
            <ChartWrapper
              userRole={userRole}
              chart={brandGraphs["Campaign Cost Breakdown Chart"]}
              imageSrc={campaignComparisonImage}
              component={
                <CampaignCostBreakdown
                  userRole={userRole}
                  chart={campaignCostGraph}
                />
              }
            />
          </TabPanel> */}
          {/* <TabPanel value={value} index={1}>
            <ChartWrapper
              userRole={userRole}
              chart={brandGraphs["Influencer Chart"]}
              imageSrc={campaignComparisonImage}
              component={
                <InfluencerPerformanceChart
                  userRole={userRole}
                  chart={influencerGraph}
                />
              }
            />
          </TabPanel> */}
          <TabPanel value={value} index={0}>
            <ChartWrapper
              userRole={userRole}
              chart={brandGraphs["Campaign Comparison Chart"]}
              imageSrc={campaignComparisonImage}
              component={
                <CampaignComparisonChart
                  userRole={userRole}
                  chart={campaignComparisonGraph}
                />
              }
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ChartWrapper
              userRole={userRole}
              chart={brandGraphs["Metrics Comparison"]}
              imageSrc={metricsGraphImage}
              component={
                <MetricsComparison userRole={userRole} chart={metricGraph} />
              }
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <ChartWrapper
              userRole={userRole}
              chart={brandGraphs["Cpe Comparison Chart"]}
              imageSrc={cpeGraphImage}
              component={
                <CpeComparisonChart userRole={userRole} chart={cpeGraph} />
              }
            />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <ChartWrapper
              userRole={userRole}
              chart={brandGraphs["Cpc Comparison Chart"]}
              imageSrc={cpcGraphImage}
              component={
                <CpcComparisonChart userRole={userRole} chart={cpcGraph} />
              }
            />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <ChartWrapper
              userRole={userRole}
              chart={brandGraphs["Cer Comparison Chart"]}
              imageSrc={cerGraphImage}
              component={
                <CerComparisonChart userRole={userRole} chart={cerGraph} />
              }
            />
          </TabPanel>
          <TabPanel value={value} index={5}>
            <ChartWrapper
              userRole={userRole}
              chart={brandGraphs["Roi Chart"]}
              imageSrc={roiChartImage}
              component={<RoiChart userRole={userRole} chart={roiChart} />}
            />
          </TabPanel>
        </Box>
      </Grid>
    </>
  );
};

export default Stats;

import { gql } from "@apollo/client";

const GET_INSTAGRAM_STATS = gql`
  query getInstagramStats($orderBy: String!, $countrySort: String) {
    getInstagramStats(orderBy: $orderBy, countrySort: $countrySort) {
      id
      accountVisibility
      accountsEngaged
      accountsReached
      femaleRatio
      following
      growthRate
      link
      maleRatio
      topAgeRange
      topCity
      topCityPercentage
      topCountry
      topCountryPercentage
      user {
        id
        image
        country
        city
        name
      }
    }
  }
`;

export default GET_INSTAGRAM_STATS;

import React from "react";
import { Grid, Typography } from "@mui/material";
import useStyles from "./styles";

const BuyMoreContent = () => {
  const classes = useStyles();

  return (
    <Grid item xs={12} container className={classes.mainGrid} alignItems="center" justifyContent="center">
      <Typography variant="h1"color="white" fontFamily="PT Sans Narrow, sans-serif">
        Coming Soon...
      </Typography>
    </Grid>
  );
}

export default BuyMoreContent;

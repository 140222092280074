import { createStyles, makeStyles } from "@mui/styles";
import { createBreakpoints } from "@mui/system";
const breakpoints = createBreakpoints({
  values: {
    xs: 0,
    sm: 576,
    md: 960,
    lg: 1280,
    xl: 1920,
  },
});

const styles = makeStyles((theme) =>
  createStyles({
    mainGrid: {
      maxWidth: "calc(100vw - 64px)!important",
      height: "calc(100vh - 64px)!important",
      padding: "20px 5px 10px 20px",
      backgroundColor: "#141414",
    },

    forceCenter: {
      [breakpoints.down("sm")]: {
        justifyContent: "center !important",
      },
    },
  })
);

export default styles;

import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Link } from "react-router-dom";
import getWindowDimensions from "./CustomHooks/windowDimensions";
import WidgetsIcon from "@mui/icons-material/Widgets";
import CampaignIcon from "@mui/icons-material/Campaign";
// import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import AssignmentIcon from "@mui/icons-material/Assignment";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import ChatIcon from "@mui/icons-material/Chat";
import PieChartIcon from "@mui/icons-material/PieChart";
import { Tooltip } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import LOGO from "../assets/img/logo.png";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  backgroundColor: "#FFA3C7",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  border: 0,
});

const closedMixin = (theme) => ({
  backgroundColor: "#FFA3C7",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  border: 0,
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const sidebarOptions = [
  {
    name: "Dashboard",
    slug: "/",
    icon: <WidgetsIcon />,
  },
  {
    name: "Campaigns",
    slug: "campaign",
    icon: <CampaignIcon />,
  },
  // {
  //   name: "Buy More",
  //   slug: "buyMoreContent",
  //   icon: <ShoppingCartIcon />,
  // },
  {
    name: "Campaigns Result",
    slug: "campaignResult",
    icon: <CampaignIcon />,
  },
  {
    name: "Metrics",
    slug: "metrics",
    icon: <LeaderboardIcon />,
  },
  {
    name: "Creators Survey",
    slug: "creatorSurvey",
    icon: <AssignmentIcon />,
  },
  {
    name: "Influencers",
    slug: "influencer",
    icon: <EmojiPeopleIcon />,
  },
  {
    name: "Chats",
    slug: "chat",
    icon: <ChatIcon />,
  },
  {
    name: "Stats",
    slug: "stats",
    icon: <PieChartIcon />,
  },
];

export default function SlideBar() {
  const [selected, setSelected] = useState("dashbaord");
  const [open, setOpen] = useState(false);
  const { width } = getWindowDimensions();

  const handleDrawerInteraction = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (width < 665) {
      setOpen(false);
    }
  }, [width]);
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Drawer variant="permanent" open={open} elevation={16} id="mainDrawer">
        {width >= 665 && (
          <DrawerHeader>
            {open && (
              <Avatar
                src={LOGO}
                sx={{ marginRight: 2, width: 150 }}
                variant="square"
              />
            )}
            <IconButton
              onClick={handleDrawerInteraction}
              sx={{ color: "#fff", backgroundColor: "#FFA3C7" }}
            >
              {open ? <ChevronLeftIcon /> : <MenuIcon />}
            </IconButton>
          </DrawerHeader>
        )}
        <List>
          {sidebarOptions.map((option, index) => (
            <Link
              to={option.slug}
              onClick={() => setSelected(option.name)}
              style={{
                color: "#fff",
                textDecoration: "none",
              }}
              key={option.name + "button" + index}
            >
              <Tooltip title={option.name} placement="right" arrow>
                <ListItem
                  key={option.name + "-" + option.index}
                  disablePadding
                  sx={{
                    display: "block",
                    backgroundColor:
                      option.name === selected ? "#EF2E83" : "#FFA3C7",
                    borderRadius: 1,
                    mb: 2,
                    "& :hover": {
                      backgroundColor:
                        option.name === selected
                          ? "#EF2E83 !important"
                          : "#FFA3C7 !important",
                      borderRadius: 1,
                    },
                  }}
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      // px: 2,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                        color: "#fff",
                      }}
                    >
                      {option.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={option.name}
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </ListItem>
              </Tooltip>
            </Link>
          ))}
        </List>
      </Drawer>
    </Box>
  );
}

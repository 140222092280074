import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Checkbox,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
} from "@mui/material";
import useStyles from "./styles";
import { useLazyQuery, useMutation } from "@apollo/client";
import GET_PRE_INVOICE from "../../graphql/queries/getPreInvoice";
import ACCEPT_INVOICE from "../../graphql/mutations/acceptInvoice";
import { Stack } from "@mui/system";
import { getCurrencySymbol } from '../sharedComponents/currencies';

const InvoicePage = (props) => {
  const classes = useStyles();
  const { campaignId, invoiceAccepted, setInvoiceAccepted, agencyCommission } = props;
  //eslint-disable-next-line
  const [approvedInfluencers, setApprovedInfluencers] = useState([]);
  const [vat, setVat] = useState("");
  const [removedInfluencers, setRemovedInfluencers] = useState([]);
  const [campaignTotal, setCampaignTotal] = useState(null);
  const [totalCommission, setTotalCommission] = useState(null);
  const [totalInfluencerFee, setTotalInfluencerFee] = useState(null);
  const [totalCurrencyType, setTotalCurrencyType] = useState();

  const [fetchInvoices] = useLazyQuery(GET_PRE_INVOICE, {
    variables: {
      userId: localStorage.getItem("currentUser"),
      campaignId: campaignId,
    },
    onCompleted: (res) => {
      setApprovedInfluencers(res.getPreInvoice);
      let total = 0;
      let commission = 0;
      let influencerFee = 0;
      res.getPreInvoice.forEach((invoice) => {
        setTotalCurrencyType(getCurrencySymbol(invoice.finalFeeType))
        total += invoice.finalFee;
        commission += (invoice.finalFee * agencyCommission) / 100;
        influencerFee =
          influencerFee +
          (invoice.finalFee - (invoice.finalFee * agencyCommission) / 100);
      });

      setCampaignTotal(total);
      setTotalCommission(commission);
      setTotalInfluencerFee(influencerFee);
      setVat(res.getPreInvoice.campaign.vat);
    },
  });

  useMemo(() => {
    fetchInvoices();
    //eslint-disable-next-line
  }, [campaignId]);

  useEffect(() => {}, [approvedInfluencers, campaignTotal]);

  function handleInfluencerCheckbox(influencer, cost, price, commission) {
    if (removedInfluencers?.includes(influencer)) {
      let temp = [...removedInfluencers];
      temp.splice(removedInfluencers.indexOf(influencer));
      setRemovedInfluencers(temp);
      setCampaignTotal(
        (currentTotal) => parseInt(currentTotal) + parseInt(cost)
      );
      setTotalInfluencerFee(
        (currentTotal) => parseInt(currentTotal) + parseInt(price)
      );
      const comm = (cost * commission) / 100;
      setTotalCommission(
        (currentTotal) => parseInt(currentTotal) + parseInt(comm)
      );
    } else {
      let temp2 = [...removedInfluencers];
      temp2.push(influencer);
      setRemovedInfluencers(temp2);
      setCampaignTotal((currentTotal) => currentTotal - cost);
      setTotalInfluencerFee((currentTotal) => currentTotal - price);
      const comm = (cost * commission) / 100;
      setTotalCommission(
        (currentTotal) => parseInt(currentTotal) - parseInt(comm)
      );
    }
  }
  const [finalize] = useMutation(ACCEPT_INVOICE);
  const finalizeInvoice = () => {
    finalize({
      variables: {
        userId: removedInfluencers,
        campaignId: campaignId,
        vat: vat,
      },
      refetchQueries: ["isCampaignInvoiced", "getCampaignDetails"],
    });
    setInvoiceAccepted(true);
  };

  useEffect(() => {}, [approvedInfluencers]);

  return (
    <Grid item xs={12} container className={classes.mainGrid}>
      <Grid
        item
        xs={12}
        container
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack
          component="span"
          direction="column"
          alignItems="center"
          justifyContent="flex-end"
        >
          <Typography variant="h4" sx={{ marginBottom: 1 }}>
            Cost Breakdown
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: 1 }} align="center">
            Below is your cost breakdown for this campaign. Please be sure to
            review it carefully.
          </Typography>
          <Typography
            variant="body1"
            sx={{ marginBottom: 3 }}
            align="center"
            color="error"
          >
            Please note when you agree to this cost breakdown, you have
            committed yourself to this campaign and will be responsible for
            paying the invoice on time
          </Typography>
        </Stack>
        <TextField
          name="VAT#"
          label="VAT#"
          type="number"
          value={vat !== "" && parseInt(vat)}
          onChange={(e) => {
            setVat(e.target.value);
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell align="left">Influencer Name</TableCell>
                <TableCell align="center">Fee</TableCell>
                <TableCell align="center">Fee Payable To Agency</TableCell>
                <TableCell align="center">Fee Payable To Influencer</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <>
                {approvedInfluencers.length > 0 &&
                  approvedInfluencers.map((influencer, index) => (
                    <TableRow
                      key={influencer.influencer.name + "-" + index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        <Checkbox
                          defaultChecked
                          sx={{
                            color: "#EF2E83",
                            "&.Mui-checked": {
                              color: "#EF2E83",
                            },
                            pointerEvents: invoiceAccepted ? "none" : "",
                          }}
                          onChange={() =>
                            handleInfluencerCheckbox(
                              influencer.influencer.id,
                              influencer.finalFee,
                              influencer.finalFee - agencyCommission,
                              agencyCommission
                            )
                          }
                        />
                      </TableCell>
                      <TableCell align="center">
                        {influencer.influencer.name}
                      </TableCell>
                      <TableCell align="center">
                        {getCurrencySymbol(influencer.finalFeeType)}{influencer.finalFee}
                      </TableCell>
                      <TableCell align="center">
                        {getCurrencySymbol(influencer.finalFeeType)}{(influencer.finalFee * agencyCommission) / 100}
                      </TableCell>
                      <TableCell align="center">
                        {getCurrencySymbol(influencer.finalFeeType)}
                        {influencer.finalFee -
                          (influencer.finalFee * agencyCommission) / 100}
                      </TableCell>
                    </TableRow>
                  ))}
              </>
              <TableRow
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  backgroundColor: "#FFA3C7",
                }}
              >
                <TableCell component="th" scope="row"></TableCell>
                <TableCell align="center" sx={{ padding: "0px !important" }}>
                  <span
                    style={{ fontSize: "1.2rem", padding: "9px 0px 10px 7px" }}
                  >
                    Total:
                  </span>
                </TableCell>
                <TableCell align="center" sx={{ fontSize: "1.2rem" }}>
                  {totalCurrencyType}{campaignTotal}
                </TableCell>
                <TableCell align="center" sx={{ fontSize: "1.2rem" }}>
                  {totalCurrencyType}{totalCommission}
                </TableCell>
                <TableCell align="center" sx={{ fontSize: "1.2rem" }}>
                  {totalCurrencyType}{totalInfluencerFee}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs container justifyContent="center">
        <Button
          variant="contained"
          onClick={() => finalizeInvoice()}
          sx={{
            mt: 5,
            "&.Mui-disabled": {
              backgroundColor: invoiceAccepted ? "rgba(46, 204, 113,1.0)" : "",
              color: "#FFF",
            },
          }}
          disabled={invoiceAccepted || campaignTotal < 1}
        >
          Accept Invoice
        </Button>
      </Grid>
    </Grid>
  );
};

export default InvoicePage;

import React, {useState} from "react";
import {
    Grid,
    Typography,
    Box,
} from "@mui/material";
import {useQuery} from "@apollo/client";
import GET_MY_SURVEYS from "../../graphql/queries/getMySurveys";
import CampaignBanner from "../../assets/creator-survey-banner-2.jpeg"
import {makeStyles} from "@mui/styles";
import SurveyPlaceholder from "./surveyPlaceholder";

const useStyles = makeStyles({
    banner: {
        height: "25vh",
        padding: "5px 5px 5px 5px",
        width: "99%",
        backgroundImage: `url(${CampaignBanner})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
        color: "#2d3436",
        marginBottom: 40,
        objectFit: "contain"
    },
})

const CreatorSurvey = () => {
    const {data} = useQuery(GET_MY_SURVEYS, {
        variables: {
            userId: localStorage.getItem("currentUser"),
        },
    });


    const classes = useStyles();

    return (
        <Grid
            item
            xs={12}
            sx={{paddingLeft: "80px", paddingTop: 5}}
            container
            justifyContent="center"
        >
            <Box className={classes.banner}/>
            <Typography
                variant="h4"
                sx={{width: "100%", marginBottom: 2}}
                align="center"
            >
                Survey Responses
            </Typography>
            <SurveyPlaceholder data={data}/>

        </Grid>
    );
};

export default CreatorSurvey;

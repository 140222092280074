import { gql } from '@apollo/client';

export const UPDATE_INFLUENCER_CONTRACT = gql`
  mutation UpdateInfluencerContract($contractId: ID!, $contract: String!) {
    updateInfluencerContract(contractId: $contractId, contract: $contract) 
  }
`;


export default UPDATE_INFLUENCER_CONTRACT

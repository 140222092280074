import { gql } from '@apollo/client';

export const UPDATE_BRAND_CONTRACT = gql`
  mutation UpdateBrandContract($campaignId: ID!, $contract: String!) {
    updateBrandContract(campaignId: $campaignId, contract: $contract) 
  }
`;


export default UPDATE_BRAND_CONTRACT

import React from 'react';

import { Select, MenuItem, InputLabel } from '@mui/material/'

const BrandSelectDropdown = ({
  labelId,
  brands,
  selectedBrand,
  handleChange,
}) => {
  return (
    <>
      <InputLabel id={labelId} sx={{ color: "#FFF", fontSize: "1rem !important" }}>
        Brand Select
      </InputLabel>
      <Select
        labelId={labelId}
        value={selectedBrand}
        variant="standard"
        onChange={handleChange}
        sx={{
          color: "#FFF",
          background: "#FFA3C7",
          borderRadius: 1,
          padding: 1,
        }}
      >
        {brands?.map((brand) => (
          <MenuItem key={brand.id} value={brand.id}>
            {brand.brandName}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

export default BrandSelectDropdown;

import { gql } from '@apollo/client';


const GET_PAID_CAMPAIGN = gql`
  query getPaidCampaigns($userId: ID!, $userRole: String, $graphId: ID)  {
    getPaidCampaigns(userId: $userId, userRole: $userRole, graphId: $graphId) {
      id
      name
      cost
      startDate
      endDate
      campaignResults {
        id
        instaImpressions
        instaLikes
        instaComments
        instaFollowers
        instaStoryViews
        instaReach
        instaPlays
        tiktokLikes
        tiktokComments
        tiktokFollowers
        tiktokViews
        tiktokShares
        discountCodeUsed
        instaNonFollowers
        instaSaves
        instaShares
        instaProfileVisits
        instaAccountsReached
        instaAccountsEngaged
        instaLinkClicks
        tiktokAverageWatchTime
        tiktokWatchFullVideo
        tiktokPlays
        tiktokSaves
        tiktokShares
      }
    }
  }`

export default GET_PAID_CAMPAIGN;

import { gql } from "@apollo/client";

const GET_INFLUENCER_FOR_POPUP = gql`
  query getInfluencer($userId: ID!) {
    getInfluencer(userId: $userId) {
      id
      name
      image
      country
      city
      tiktok
      instagram
      userGalleries {
        id
        item
      }
      instagramStat {
        id
        accountVisibility
        accountsEngaged
        accountsReached
        following
        link
      }
      tiktokStat {
        id
        accountVisibility
        accountsEngaged
        following
        link
        videoViews
      }
    }
  }
`;

export default GET_INFLUENCER_FOR_POPUP;

import React, { useEffect, useState } from "react";
import { useNavigate, Route, Routes } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
// import axios from "axios";
import Login from "./components/auth/login";
import SignUp from "./components/auth/signup.js";
import SlideBar from "./components/SideBar";
import Header from "./components/header";
import Dashboard from "./components/dashboard/dashboard";
import Metrics from "./components/metrics";
import Influencer from "./components/influencer/influencer";
import Campaign from "./components/campaigns/campaign";
import AddCampaigns from "./components/campaigns/addCampaigns";
import Profile from "./components/profile/profile";
import BuyMoreContent from "./components/buyMoreContent/buyMoreContent";
import CampaignResult from "./components/campaignResult/campaignResult";
import CreatorSurvey from "./components/creatorSurvey";
import CampaignApproved from "./components/campaigns/campaignApproved";
import CampaignsDetail from "./components/campaigns/campaignDetails";
import Stats from "./components/stats";
import Chat from "./components/Chat";
import PendingCampaign from "./components/campaigns/pendingCampaign";
import { Alert, Grid, Snackbar } from "@mui/material";
import { useMutation } from "@apollo/client";
import LOGIN from "./graphql/mutations/login";
import { toast } from "react-toastify";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("currentUser")
  );
  const [error, setError] = useState(null);
  const nav = useNavigate();
  const [loginUser] = useMutation(LOGIN, {
    onCompleted: (res) => {
      if (res.userLogin2.authenticatable.accountStatus !== "Pending") {
        localStorage.setItem("currentUser", res.userLogin2.authenticatable.id);
        localStorage.setItem(
          "accessToken",
          res.userLogin2.credentials.accessToken
        );
        localStorage.setItem("client", res.userLogin2.credentials.client);
        localStorage.setItem("uid", res.userLogin2.credentials.uid);
        localStorage.setItem("userRole", res?.userLogin2?.authenticatable?.userType)
        setIsLoggedIn(true);
        nav("/");
        res.userLogin2.authenticatable?.image === null &&
          toast.success("Please upload a Profile Picture");
        res.userLogin2.authenticatable?.tiktok === null &&
          toast.success("Please add a link to your TikTok account");
        res.userLogin2.authenticatable?.instagram === null &&
          toast.success("Please add a link to your Instagram account");
      } else {
        setError("Your accounts is pending admin approval");
      }
    },
    onError: (err) => {
      setError(err.message);
    },
  });

  useEffect(() => {}, [isLoggedIn]);

  async function handleLogout() {
    localStorage.removeItem("currentUser");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("client");
    localStorage.removeItem("uid");
    setIsLoggedIn(false);
    nav("/login");
  }

  async function handleLogin(email, password) {
    loginUser({
      variables: {
        email: email,
        password: password,
      },
    });
  }

  const ProtectedRoute = ({ auth, children }) => {
    return <>{auth ? children : <Login handleLogin={handleLogin} />}</>;
  };

  return (
    <Grid item xs={12} container>
      {error && (
        <Snackbar
          open={error}
          autoHideDuration={3000}
          onClose={() => setError(null)}
        >
          <Alert severity="error" sx={{ width: "100%" }}>
            {error}
          </Alert>
        </Snackbar>
      )}
      {isLoggedIn && <SlideBar />}
      <Grid
        item
        xs={12}
        container
        direction="row"
        id="mainBody"
        justifyContent="flex-end"
      >
        {isLoggedIn && <Header handleLogout={handleLogout} />}
        <Routes>
          <Route path="/login" element={<Login handleLogin={handleLogin} />} />
          <Route path="/SignUp" element={<SignUp />} />
          <Route
            exact
            path="/"
            element={
              <ProtectedRoute auth={isLoggedIn}>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/campaign"
            element={
              <ProtectedRoute auth={isLoggedIn}>
                <Campaign />
              </ProtectedRoute>
            }
          />
          <Route
            path="/metrics"
            element={
              <ProtectedRoute auth={isLoggedIn}>
                <Metrics />
              </ProtectedRoute>
            }
          />
          <Route
            path="/influencer"
            element={
              <ProtectedRoute auth={isLoggedIn}>
                <Influencer />
              </ProtectedRoute>
            }
          />
          <Route
            path="/addCampaign"
            element={
              <ProtectedRoute auth={isLoggedIn}>
                <AddCampaigns />
              </ProtectedRoute>
            }
          />
          <Route
            path="/buyMoreContent"
            element={
              <ProtectedRoute auth={isLoggedIn}>
                <BuyMoreContent />
              </ProtectedRoute>
            }
          />
          <Route
            path="/campaignResult"
            element={
              <ProtectedRoute auth={isLoggedIn}>
                <CampaignResult />
              </ProtectedRoute>
            }
          />
          <Route
            path="/creatorSurvey"
            element={
              <ProtectedRoute auth={isLoggedIn}>
                <CreatorSurvey />
              </ProtectedRoute>
            }
          />
          <Route
            path="/profile"
            element={
              <ProtectedRoute auth={isLoggedIn}>
                <Profile />
              </ProtectedRoute>
            }
          />
          <Route
            path="/chat"
            element={
              <ProtectedRoute auth={isLoggedIn}>
                <Chat />
              </ProtectedRoute>
            }
          />
          <Route
            path="/campaignsDetail/:id"
            element={
              <ProtectedRoute auth={isLoggedIn}>
                <CampaignsDetail />
              </ProtectedRoute>
            }
          />
          <Route
            path="/campaignsApproved/:id"
            element={
              <ProtectedRoute auth={isLoggedIn}>
                <CampaignApproved />
              </ProtectedRoute>
            }
          />
          <Route
            path="/pendingCampaign/:id"
            element={
              <ProtectedRoute auth={isLoggedIn}>
                <PendingCampaign />
              </ProtectedRoute>
            }
          />
          <Route
            path="/stats"
            element={
              <ProtectedRoute auth={isLoggedIn}>
                <Stats />
              </ProtectedRoute>
            }
          />
        </Routes>
      </Grid>
      <ToastContainer />
    </Grid>
  );
}

export default App;

import React, { useState } from 'react';
import CurrencyType from './CurrencyType';

const BudgetInput = ({ onBudgetChange, onBudgetTypeChange }) => {
  const budgetValue = localStorage.getItem('budget');
  const budgetType = localStorage.getItem('budgetType');

  const handleBudgetValue = (event) => {
    const value = event.target.value;
    localStorage.setItem('budget', value);

    // Call the callback function to pass the budget value to the parent component
    onBudgetChange(value);
  };


  const handleBudgetType = (value) => {
    localStorage.setItem('budgetType', value);

    // Call the callback function to pass the budget value to the parent component
    onBudgetTypeChange(value);
  };

  return (
  <div style={{marginTop: "10px"}}>
    <CurrencyType currencyType={budgetType} setCurrencyType={handleBudgetType} width={"100px"} backgroundColor={"#FFF"}/>
    <input
      placeholder="Add your budget"
      style={{
        backgroundColor: "#FFF",
        height: "48px",
        padding: "4px",
        marginRight: "58px"
      }}
      onChange={handleBudgetValue}
      value={budgetValue}
    />
  </div>
  );
};

export default BudgetInput;

import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";

import {
    Grid,
    TableContainer,
    Paper,
    Table,
    TableHead,
    Typography,
    TableBody,
    TableRow,
    TableCell,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
} from "@mui/material";
import useStyles from "./styles";
import CampaignDetailBanner from "./campaignDetailBanner";
import ClearIcon from "@mui/icons-material/Clear";
import CheckIcon from "@mui/icons-material/Check";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import TaskGallery from "./taskGallery";
import {useMutation, useQuery} from "@apollo/client";
import GET_TASK_SUBMISSIONS from "../../graphql/queries/getTaskSubmissions";
import ACCEPT_SUBMISSION from "../../graphql/mutations/acceptSubmission";
import REJECT_SUBMISSION from "../../graphql/mutations/rejectSubmission";
import FINISHED_CAMPAIGN from "../../graphql/mutations/finishedCampaign";
import GET_CAMPAIGN_DETAILS from "../../graphql/queries/getCampaignDetails";
import {useNavigate} from "react-router-dom";
import TaskModal from './taskModal'
import DownloadContract from "./DownloadContract";

const TableHeaders = () => {
    return (<TableHead>
        <TableRow>
            <TableCell>Influencer</TableCell>
            <TableCell align="center">Task Description</TableCell>
            <TableCell align="center">Actions</TableCell>
            <TableCell align="center">Content Type</TableCell>
            <TableCell align="center">Content Media</TableCell>
            <TableCell align="center">Accept</TableCell>
            <TableCell align="center">Reject</TableCell>
            <TableCell align="center">View Submissions</TableCell>
        </TableRow>
    </TableHead>);
};

const UserInfo = ({src, name}) => {
    return (<Grid item xs container alignItems="center">
        {src ? (<img src={src} width="50" alt="avatar"/>) : (
            <AccountCircleIcon fontSize="large" sx={{color: "#FFA3C7"}}/>)}
        <Typography variant="subtitle1" sx={{marginLeft: 2}}>
            {name}
        </Typography>
    </Grid>);
};

const CampaignsDetail = () => {
    const classes = useStyles();
    const {id} = useParams();
    const [campaignFinished, setCampaignFinished] = useState(false);
    const [selectedSubmissions, setSelectedSubmissions] = useState([]);
    const [tasksPending, setTasksPending] = useState(false);
    const [error, showError] = useState(false);
    const [campaignType, setCampaignType] = useState("");
    const [campaign, setCampaign] = useState("");

    const navigate = useNavigate();

    const {getBrandFinishCampaign} = useQuery(GET_CAMPAIGN_DETAILS, {
        variables: {
            campaignId: id,
        }, onCompleted: (res) => {
            setCampaign(res.getCampaignDetails)
            if (res.getCampaignDetails.status == 'Finished') {
                setCampaignFinished(true);
            } else {
                setCampaignFinished(false);
            }
        }
    });

    useEffect(() => {
        const getFinishCampaign = getBrandFinishCampaign;
    });

    const {data} = useQuery(GET_TASK_SUBMISSIONS, {
        fetchPolicy: "network-only", variables: {
            campaignId: id,
        }, onCompleted: (resp) => {
            var submissions = [];
            resp?.brandGetTaskSubmissions?.forEach((contract, index) => contract.campaignTasks.forEach((task) => {
                task.imageSubmissions.forEach((image) => submissions.push(image));
                task.videoSubmissions.forEach((image) => submissions.push(image));
                if (task.status === "Pending" || task.status === "Rejected") {
                    setTasksPending(true);
                }
            }));
            setSelectedSubmissions(submissions);
        },
    });

    const [acceptSubmission] = useMutation(ACCEPT_SUBMISSION, {
        refetchQueries: ["brandGetTaskSubmissions"],
    });

    const [rejectSubmission] = useMutation(REJECT_SUBMISSION, {
        refetchQueries: ["brandGetTaskSubmissions"],
    });

    const [brandFinishCampaign] = useMutation(FINISHED_CAMPAIGN, {
        onCompleted: (res) => {
            setCampaignFinished(res.brandFinishedCampaign);
        }
    });

    function setSubmissions(images, videos) {
        const submissions = [...images, ...videos];
        setSelectedSubmissions(submissions);
    }

    function resetFilter() {
        var submissions = [];
        data?.brandGetTaskSubmissions?.forEach((contract, index) => contract?.campaignTasks?.forEach((task) => {
            task.imageSubmissions?.forEach((image) => submissions.push(image));
            task?.videoSubmissions?.forEach((image) => submissions.push(image));
        }));
        setSelectedSubmissions(submissions);
    }

    function openfinishCampaignModel() {
        showError(true);
    }

    function finishCampaign() {
        showError(false);
        brandFinishCampaign({
            variables: {
                campaignId: id
            },
        });
    }

    const handleRejectSubmissions = (taskId, influencerId) => {
        rejectSubmission({
            variables: {taskId},
        });
        navigate(`/chat?campaignId=${id}&influencerId=${influencerId}`);
    };

    const [taskModal, openTaskModal] = useState(false);
    const [openTask, setOpenTask] = useState();


    if (!data) return <> </>;
    return (<Grid
        item
        xs={12}
        container
        className={classes.mainGrid}
        justifyContent="center"
    >
        <Grid
            item
            xs={12}
            container
            justifyContent="flex-end"
            sx={{marginBottom: 2}}
        >

            <Button
                variant="contained"
                color="success"
                onClick={() => openfinishCampaignModel()}
                disabled={campaignFinished}
            >
                Finish Campaign
            </Button>

            <CampaignDetailBanner
                key={parseInt(id)}
                campaignId={parseInt(id)}
                interestedCount={5}
                setCampaignType={setCampaignType}
            />
            <TableContainer
                component={Paper}
                sx={{marginTop: 5, marginBottom: 3}}
            >
                <Table sx={{minWidth: 650}} aria-label="simple table">
                    <TableHeaders/>
                    <TableBody>
                        {data?.brandGetTaskSubmissions?.map((contract, index) => contract?.campaignTasks?.map((task) => {
                            return (<TableRow
                                key={"contract-" + contract?.id + "-" + contract?.influencer?.id + "-" + task?.id}
                                sx={{
                                    "&:last-child td, &:last-child th": {border: 0},
                                }}
                            >
                                <TableCell
                                    component="td"
                                    scope="row"
                                    style={{verticalAlign: "center", maxWidth: 200}}
                                >
                                    {<UserInfo
                                        src={contract?.influencer?.image}
                                        name={contract?.influencer?.name}
                                    />}
                                </TableCell>
                                <TableCell align="center" style={{
                                    overflowWrap: "anywhere", whiteSpace: "pre-wrap"
                                }}>{task?.description}</TableCell>
                                <TableCell align="center">
                                    <Button
                                        onClick={() => {
                                            openTaskModal(true)
                                            setOpenTask(task)
                                        }}>
                                        Edit Task
                                    </Button>
                                </TableCell>
                                <TableCell align="center" style={{
                                    overflowWrap: "anywhere", whiteSpace: "pre-wrap"
                                }}>{task?.taskMedia}</TableCell>
                                <TableCell align="center" style={{
                                    overflowWrap: "anywhere", whiteSpace: "pre-wrap"
                                }}>{task?.taskType}</TableCell>
                                <TableCell align="center">
                                    {task.status !== "Accepted" && (<Button
                                        onClick={() => acceptSubmission({
                                            variables: {taskId: task?.id},
                                        })}
                                        disabled={!(task?.imageSubmissions?.length > 0 || task?.videoSubmissions?.length > 0) || task.status === "Accepted"}
                                        aria-label="accept submission"
                                        sx={{
                                            backgroundColor: task?.imageSubmissions?.length > 0 || task?.videoSubmissions?.length > 0 ? "#2ECC71" : "rgba(0, 0, 0, 0.26)",
                                        }}
                                    >
                                        <CheckIcon htmlColor="#FFFFFF"/>
                                    </Button>)}
                                </TableCell>
                                <TableCell align="center">
                                    {task?.status !== "Accepted" && (<Button
                                        onClick={() => handleRejectSubmissions(task?.id, contract?.influencer?.id)}
                                        disabled={!(task?.imageSubmissions?.length > 0 || task?.videoSubmissions?.length > 0) || task?.status === "Accepted"}
                                        aria-label="accept submission"
                                        sx={{
                                            backgroundColor: task?.imageSubmissions?.length > 0 || task?.videoSubmissions?.length > 0 ? "#E74C3C" : "rgba(0, 0, 0, 0.26)",
                                        }}
                                    >
                                        <ClearIcon htmlColor="#FFFFFF"/>
                                    </Button>)}
                                </TableCell>
                                <TableCell align="center">
                                    <Button
                                        onClick={() => setSubmissions(task?.imageSubmissions, task?.videoSubmissions)}
                                        disabled={!(task?.imageSubmissions?.length > 0 || task?.videoSubmissions?.length > 0)}
                                        variant="contained"
                                        aria-label="view submission"
                                        sx={{
                                            backgroundColor: "primary",
                                        }}
                                    >
                                        View Submissions
                                    </Button>
                                </TableCell>
                                <TableCell align="center">
                                    <DownloadContract campaign={campaign} contract={contract}/>
                                </TableCell>
                            </TableRow>);
                        }))}
                    </TableBody>
                </Table>
            </TableContainer>
            {selectedSubmissions && (<TaskGallery
                submissions={selectedSubmissions}
                reset={() => resetFilter}
            />)}
        </Grid>
        <Dialog onClose={() => showError(false)} open={error}>
            <DialogTitle id="responsive-dialog-title">
                {"Are you sure you want to finish"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText align="center">
                    There are still submissions pending.
                    <br/>
                    Are you sure you want to finish this campaign?
                    <br/>
                    <b style={{color: "red"}}>This action cannot be undone</b>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={() => showError(false)}>
                    Cancel
                </Button>
                <Button onClick={finishCampaign} autoFocus>
                    Yes, Finish
                </Button>
            </DialogActions>
        </Dialog>

        <TaskModal
            campaignId={id}
            open={taskModal}
            handleClose={openTaskModal}
            task={openTask}
            refetchQuery="brandGetTaskSubmissions"
        />
    </Grid>);
};

export default CampaignsDetail;

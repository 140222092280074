import { gql } from "@apollo/client";

const GET_PRE_INVOICE = gql`
  query getPreInvoice($userId: ID!, $campaignId: ID!) {
    getPreInvoice(userId: $userId, campaignId: $campaignId) {
      id
      finalFee
      finalFeeType      
      influencerQuote
      platformFee
      influencer {
        id
        image
        name
      }
    }
  }
`;

export default GET_PRE_INVOICE;

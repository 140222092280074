
const integrationRedirection = ({ userName, integrationType}) => {

  //adding this check as have stored the handler in the DB for instagram starting with @. we need to fix the back-end data then we can remove this check.
  if(integrationType == 'instagram') {
    userName = userName?.startsWith('@') ? userName.slice(1) : userName;
  }

  if (/Mobi|Android/i.test(navigator.userAgent)) {
      // Attempt to open Instagram app on mobile
      window.location.href = `${integrationType}://user?username=${userName}`;
  } else {
    // Open Instagram web profile on desktop (and mobile devices without the app)
    window.open(`https://www.${integrationType}.com/${userName}`, "_blank");
  }

}

export default integrationRedirection
import { gql } from "@apollo/client";

const FETCH_NOTIFICATIONS = gql`
  query brandGetNotifications($userId: ID!) {
    brandGetNotifications(userId: $userId) {
      id
      createdAt
      text
      url
    }
  }
`;

export default FETCH_NOTIFICATIONS;

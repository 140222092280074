// @mui
import { Container, Stack, Typography } from "@mui/material";
// mock
import ArchiveCampaignSort from "./ArchiveCampaignSort";
import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import NewPaidCampaignCard from "./NewPaidCampaignCard";
import GET_FINISHED_CAMPAIGNS from "../graphql/queries/getFinishedCampaignsForInfluencer";
import GET_STARTED_CAMPAIGNS from "../graphql/queries/getStartedCampaignsForInfluencer";

// ----------------------------------------------------------------------

export default function ArchiveCampaigns() {
  const [activeFilter, setActiveFilter] = useState("Started");
  const [data, setData] = useState();

  const [fetchFinised] = useLazyQuery(GET_FINISHED_CAMPAIGNS, {
    fetchPolicy: "network-only",
    variables: {
      userId: localStorage.getItem("currentUser"),
    },
    onCompleted: (res) => {
      setData(res.getBrandCampaignsFinished);
    },
  });

  const [fetchStarted] = useLazyQuery(GET_STARTED_CAMPAIGNS, {
    fetchPolicy: "network-only",
    variables: {
      userId: localStorage.getItem("currentUser"),
    },
    onCompleted: (res) => {
      setData(res.getBrandCampaignsStarted);
    },
  });

  useEffect(() => {
    switch (activeFilter) {
      case "Started":
        fetchStarted();
        break;
      case "Finished":
        fetchFinised();
        break;
    }
    //eslint-disable-next-line
  }, [activeFilter]);

  useEffect(() => {
    console.log("data", data);
  }, [fetchFinised, fetchStarted]);

  return (
    <>
      <title> Campaigns </title>

      <div style={{marginLeft: "40px"}}>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Campaigns
        </Typography>

        <Stack
          direction="row"
          flexWrap="wrap-reverse"
          alignItems="center"
          justifyContent="flex-end"
          sx={{ mb: 5 }}
        >
          <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
            <ArchiveCampaignSort setActiveFilter={setActiveFilter} />
          </Stack>
        </Stack>

        {data && (
          <Grid container spacing={3}>
            {data.map((campaign) => (
              <Grid key={campaign.id} item xs={12} sm={6} md={3}>
                <NewPaidCampaignCard campaign={campaign} />
              </Grid>
            ))}
          </Grid>
        )}
      </div>
    </>
  );
}

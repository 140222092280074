import { gql } from "@apollo/client";

const ACCEPT_GIFTED_OFFER = gql`
  mutation brandAcceptGiftedContract($contractId: ID!, $influencerId: ID!) {
    brandAcceptGiftedContract(
      contractId: $contractId
      influencerId: $influencerId
    )
  }
`;

export default ACCEPT_GIFTED_OFFER;

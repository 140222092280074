import { gql } from "@apollo/client";

const GET_BRAND_GRAPHS = gql`
query getBrandGraphs($userId: ID!) {
  getBrandGraphs(userId: $userId) {
    id
    published
    campaignId
    dashboardGraph {
      id
      name
    }
  }
}
`;

export default GET_BRAND_GRAPHS;

import React from "react";
import { Chip, Grid, Stack, Typography } from "@mui/material";
import useStyles from "./styles";
import { useQuery } from "@apollo/client";
import GET_CAMPAIGN_DETAILS from "../../../graphql/queries/getCampaignDetails";
import moment from 'moment';

const statusColor = {
  In_Progress: "#FFA3C7",
  Approved: "#6ab04c",
  Pending: "#f9ca24",
};
const statusName = {
  In_Progress: "In-Progress",
  Approved: "Approved",
  Pending: "Waiting Approval",
};

const CampaignDetailBanner = (props) => {
  const { campaignId, influencerCount, setCampaignType, setCampaign } = props;
  const classes = useStyles();
  const { data } = useQuery(GET_CAMPAIGN_DETAILS, {
    fetchPolicy: "network-only",
    variables: {
      campaignId: campaignId,
    },
    onCompleted: (res) => {
      setCampaign(res.getCampaignDetails)
      setCampaignType(res.getCampaignDetails.campaignType);
    },
  });
  const formatDate = (date) =>{
    return moment.utc(date).format('DD/MM/YYYY')
  }
  return (
    <Grid
      item
      xs={12}
      container
      className={classes.campaignDetailBanner}
      justifyContent="start"
      alignItems="center"
    >
      <Grid item xs={12} sm={3} sx={{ marginTop: "4px", marginRight: "10px" }}>
        <img
          src={data?.getCampaignDetails.coverPic}
          alt={data?.getCampaignDetails.name + "-campaign cover"}
          className={classes.coverImage}
        />
      </Grid>
      <Grid item xs={12} sm={8} container justifyContent="space-between">
        <Grid item xs={12} container direction="row" alignItems="center">
          <Typography variant="h5">{data?.getCampaignDetails.name}</Typography>
          &emsp;
          <Typography
            variant="caption"
            sx={{
              color: "#ffffff",
              backgroundColor: statusColor[data?.getCampaignDetails.status],
              padding: "1px 10px",
              borderRadius: "10px",
            }}
          >
            {statusName[data?.getCampaignDetails.status]}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          container
          direction="row"
          justifyContent="space-around"
        >
          <Grid item xs={4} container direction="column" justifyContent="start">
            <Stack
              direction="column"
              sx={{
                marginTop: "10px",
                marginRight: "10px",
                alignItems: "center",
              }}
            >
              <Chip
                label="Campaign Type"
                size="medium"
                variant="outlined"
                sx={{ width: "140px", borderColor: "#FFA3C7" }}
              />
              <Chip
                label={data?.getCampaignDetails.campaignType}
                size="medium"
                color="success"
                sx={{
                  width: "140px",
                  marginTop: "8px",
                  backgroundColor: "#FFA3C7",
                  fontWeight: 700,
                }}
              />
            </Stack>
            <Stack
              direction="column"
              sx={{
                marginTop: "10px",
                marginRight: "10px",
                alignItems: "center",
              }}
            >
              <Chip
                label="Campaign Category"
                size="medium"
                variant="outlined"
                sx={{ width: "160px", borderColor: "#FFA3C7" }}
              />
              <Chip
                label={data?.getCampaignDetails.category.name}
                size="medium"
                color="success"
                sx={{
                  width: "250px",
                  marginTop: "8px",
                  backgroundColor: "#FFA3C7",
                  fontWeight: 700,
                }}
              />
            </Stack>
          </Grid>
          <Grid item xs={3} container direction="column" justifyContent="start">
            <Stack
              direction="column"
              sx={{ marginTop: "10px", marginRight: "10px" }}
            >
              <Chip
                label="Interested People"
                size="medium"
                variant="outlined"
                sx={{ width: "140px", borderColor: "#FFA3C7" }}
              />
              <Chip
                label={influencerCount}
                size="medium"
                color="success"
                sx={{
                  width: "140px",
                  marginTop: "8px",
                  backgroundColor: "#FFA3C7",
                  fontWeight: 700,
                }}
              />
            </Stack>
            <Stack
              direction="column"
              sx={{ marginTop: "10px", marginRight: "10px" }}
            >
              <Chip
                label="Campaign Media"
                size="medium"
                variant="outlined"
                sx={{ width: "140px", borderColor: "#FFA3C7" }}
              />
              <Chip
                label={data?.getCampaignDetails.media}
                size="medium"
                color="success"
                sx={{
                  width: "140px",
                  marginTop: "8px",
                  backgroundColor: "#FFA3C7",
                  fontWeight: 700,
                }}
              />
            </Stack>
          </Grid>
          <Grid item xs={2} container justifyContent="start">
            <Stack
              direction="column"
              sx={{
                marginTop: "10px",
                marginRight: "10px",
                alignItems: "center",
              }}
            >
              <Chip
                label="No. of Followers"
                size="medium"
                variant="outlined"
                sx={{
                  width: "140px",
                  marginBottom: "8px",
                  borderColor: "#FFA3C7",
                }}
              />
              {data?.getCampaignDetails.followerPreference.map((follower) => (
                <Chip
                  label={follower}
                  size="medium"
                  sx={{
                    marginTop: "4px",
                    backgroundColor: "#FFA3C7",
                    color: "#FFFFFF",
                    fontWeight: 700,
                  }}
                />
              ))}
            </Stack>
            <Stack
              direction="column"
              sx={{
                marginTop: "10px",
                marginRight: "10px",
                alignItems: "center",
              }}
            >
              <Chip
                label="Start Date"
                size="medium"
                variant="outlined"
                sx={{
                  width: "140px",
                  marginBottom: "8px",
                  borderColor: "#FFA3C7",
                }}
              />
              {data?.getCampaignDetails?.startDate && (<Chip
                  label={formatDate(data?.getCampaignDetails?.startDate)}
                  size="medium"
                  sx={{
                    marginTop: "4px",
                    backgroundColor: "#FFA3C7",
                    color: "#FFFFFF",
                    fontWeight: 700,
                  }}
                />)}
            </Stack>
          </Grid>
          <Grid item xs={2} container justifyContent="start">
            <Stack
              direction="column"
              sx={{
                marginTop: "10px",
                marginRight: "10px",
                alignItems: "center",
              }}
            >
              <Chip
                label="Age Group"
                size="medium"
                variant="outlined"
                sx={{
                  width: "140px",
                  marginBottom: "8px",
                  borderColor: "#FFA3C7",
                }}
              />
              {data?.getCampaignDetails.ageGroup.map((age) => (
                <Chip
                  label={age}
                  size="medium"
                  sx={{
                    marginTop: "4px",
                    backgroundColor: "#FFA3C7",
                    color: "#FFFFFF",
                    fontWeight: 700,
                  }}
                />
              ))}
            </Stack>

            <Stack
              direction="column"
              sx={{
                marginTop: "10px",
                marginRight: "10px",
                alignItems: "center",
              }}
            >
              <Chip
                label="End Date"
                size="medium"
                variant="outlined"
                sx={{
                  width: "140px",
                  marginBottom: "8px",
                  borderColor: "#FFA3C7",
                }}
              />
                {data?.getCampaignDetails?.endDate && (<Chip
                  label={formatDate(data?.getCampaignDetails?.endDate)}
                  size="medium"
                  sx={{
                    marginTop: "4px",
                    backgroundColor: "#FFA3C7",
                    color: "#FFFFFF",
                    fontWeight: 700,
                  }}
                />)}
            </Stack>
          </Grid>

           
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CampaignDetailBanner;

import {
  Button,
  Grid,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListItemIcon,
  Checkbox,
  ListItemText,
  FormControlLabel,
  Stack,
  DialogContent,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import useStyles from "./styles";
import { pink } from "@mui/material/colors";
import ProfilePicUploader from "../auth/profilePicUploader";
import { useMutation, useQuery } from "@apollo/client";
import CREATE_CAMPAIGN from "../../graphql/mutations/createCampaign";
import GET_CAMPAIGN_CATEGORIES from "../../graphql/queries/getCampaignCategories";
import { PinkButton } from "../common-components/customComponents";
import CurrencyType from "../sharedComponents/CurrencyType";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import S3 from "aws-s3";
import s3_config from "../../config/s3_config";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const theme = createTheme({
  components: {
    MuiPickersDay: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: "rgb(255, 163, 199) !important",
            color: "white",
            "&:hover": {
              backgroundColor: "rgb(255, 163, 199)", // Change hover color
            },
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        formControl: {
          color: "#EF2D83", // Change label color
        },
      },
    },
  },
});

const S3Client = new S3(s3_config);

const Campaign_type = [
  { value: 0, label: "Gifted" },
  { value: 1, label: "Paid" },
];

const Campaign_Media = [
  { value: 0, label: "Instagram" },
  { value: 1, label: "Tiktok" },
  { value: 2, label: "Both" },
];

const Age_Group = [
  { value: "18 - 24", label: "18 - 24" },
  { value: "25 - 34", label: "25 - 34" },
  { value: "35 - 44", label: "35 - 44" },
  { value: "45 - 54", label: "45 - 54" },
  { value: "55 - 64", label: "55 - 64" },
  { value: "65+", label: "65+" },
];

const No_of_Followers = [
  { value: "Under 10K", label: "Under 10K" },
  { value: "10K - 30K", label: "10K - 30K" },
  { value: "30K - 50K", label: "30K - 50K" },
  { value: "50K - 70K", label: "50K - 70K" },
  { value: "70K+", label: "70K+" },
];

const usageRightsPeriods = [
  { value: "None", label: "None" },
  { value: "Thirty", label: "30 Days" },
  { value: "Forty_Five", label: "45 Days" },
  { value: "Sixty", label: "60 Days" },
  { value: "Ninety", label: "90 Days" },
  { value: "Hundred_Eighty", label: "180 Days" },
  { value: "Year", label: "1 Year" },
];
const AddCampaigns = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [selectedAge, setSelectedAge] = useState([]);
  const [selectedFollower, setSelectedFollower] = useState([]);
  const [agreed, setAgreed] = useState(false);
  const { data: categories } = useQuery(GET_CAMPAIGN_CATEGORIES);
  const [defaultContract, setDefaultContract] = useState();
  const [extendedContract, setExtendedContract] = useState();
  const [policy, setPolicy] = useState();
  const [openPolicyModal, setOpenPolicyModal] = useState(false);
  const [isPolicyAccepted, setIsPolicyAccepted] = useState(false);

  const [addExtendedContract, setAddExtendedContract] = useState(false);
  const [makeCampaign] = useMutation(CREATE_CAMPAIGN, {
    onCompleted: () => {
      toast.success("Campaign Created Successfully");
      navigate("/campaign");
    },
  });
  const isAllSelected =
    Age_Group.length > 0 && selectedAge.length === Age_Group.length;
  const isFollowerSelected =
    No_of_Followers.length > 0 &&
    selectedFollower.length === No_of_Followers.length;

  const handleFollowerChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setSelectedFollower(
        selectedFollower.length === No_of_Followers.length
          ? []
          : No_of_Followers.map((follower) => follower.value)
      );
      return;
    }
    setSelectedFollower(value);
  };
  const handleAgeChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setSelectedAge(
        selectedAge.length === Age_Group.length
          ? []
          : Age_Group.map((age) => age.value)
      );
      return;
    }
    setSelectedAge(value);
  };
  const handleAgreement = (event) => {
    setAgreed(event.target.checked);
  };
  const handleImageUpload = (image) => {
    let newGallery = formInput.galleries;
    newGallery.push(image);
    setFormInput({ ...formInput, galleries: [...newGallery] });
  };
  const [formInput, setFormInput] = useState({
    title: "",
    category_id: 1,
    campaign_type: 0,
    campaign_media: 0,
    age_group: [],
    no_of_followers: [],
    male_ratio: 0,
    female_ratio: 0,
    description: "",
    brand_id: 0,
    galleries: [],
    reels: false,
    stories: false,
    videos: false,
    pictures: false,
    requiresUsageRights: false,
    usageRightsPeriod: "None",
    cost: 0,
    commission_model: false,
  });

  const createCampaign = async () => {
    if (extendedContract) {
      const blob = new Blob([extendedContract], { type: "text/plain" });
      S3Client.uploadFile(blob)
        .then((data) => {
          makeCampaign({
            variables: {
              name: formInput.title,
              categoryId: formInput.category_id,
              campaignType: formInput.campaign_type,
              media: formInput.campaign_media,
              ageGroup: selectedAge,
              malePercentage: parseInt(formInput.male_ratio),
              femalePercentage: parseInt(formInput.female_ratio),
              description: formInput.description,
              userId: localStorage.getItem("currentUser"),
              gallery: formInput.galleries,
              followerPreference: selectedFollower,
              contract: data.location,
              reels: formInput.reels,
              stories: formInput.stories,
              videos: formInput.videos,
              pictures: formInput.pictures,
              requiresUsageRights: formInput.requiresUsageRights,
              usageRightsPeriod: formInput.usageRightsPeriod,
              cost: parseInt(formInput.cost),
              costType: currencyType,
              startDate: startDate,
              endDate: endDate,
              commissionModel: formInput.commission_model,
            },
          });
        })
        .catch((err) => {});
    } else {
      makeCampaign({
        variables: {
          name: formInput.title,
          categoryId: formInput.category_id,
          campaignType: formInput.campaign_type,
          media: formInput.campaign_media,
          ageGroup: selectedAge,
          malePercentage: parseInt(formInput.male_ratio),
          femalePercentage: parseInt(formInput.female_ratio),
          description: formInput.description,
          userId: localStorage.getItem("currentUser"),
          gallery: formInput.galleries,
          followerPreference: selectedFollower,
          reels: formInput.reels,
          stories: formInput.stories,
          videos: formInput.videos,
          pictures: formInput.pictures,
          requiresUsageRights: formInput.requiresUsageRights,
          usageRightsPeriod: formInput.usageRightsPeriod,
          cost: parseInt(formInput.cost),
          costType: currencyType,
          startDate: startDate,
          endDate: endDate,
          commissionModel: formInput.commission_model,
        },
      });
    }
  };

  const [currencyType, setCurrencyType] = useState("USD");
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());

  const loadContract = async () => {
    await fetch(
      "https://authentic-pps.s3.eu-west-2.amazonaws.com/authentic_default_contract.txt"
    ).then((r) => {
      r.text().then((d) => {
        setDefaultContract(d);
      });
    });
  };

  const loadFrameworkPolicy = () => {
    fetch("/frameworkPolicy2.txt")
      .then((response) => response.text())
      .then((text) => {
        console.log("🚀 ~ .then ~ text:", text);
        setPolicy(text);
      });
  };

  useEffect(() => {
    loadContract();
    loadFrameworkPolicy();
  }, []);

  // const Transition = React.forwardRef(function Transition(props, ref) {
  //   return <Slide direction="up" ref={ref} {...props} />;
  // });

  return (
    <>
      <Dialog
        open={openPolicyModal}
        // TransitionComponent={Transition}
        keepMounted
        maxWidth="300px"
        onClose={() => setOpenPolicyModal(false)}
      >
        <DialogTitle style={{textAlign: "center"}}>AUTHENTIC FRAMEWORK TERMS AND PRIVACY POLICY</DialogTitle>
        <DialogContent>
          <div dangerouslySetInnerHTML={{ __html: policy }} />
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setOpenPolicyModal(false)}>Close</Button>
          <Button
            onClick={() => {
              setIsPolicyAccepted(true);
              setOpenPolicyModal(false);
            }}
          >
            Accept
          </Button>
        </DialogActions>
      </Dialog>
      <Grid
        item
        xs={12}
        container
        justifyContent="space-around"
        sx={{ mt: 4, paddingLeft: "64px" }}
        alignItems="start"
      >
        <Grid
          item
          xs={12}
          container
          justifyContent="center"
          sx={{ marginBottom: 4 }}
        >
          <Typography variant="h5"> Create New Campaign </Typography>
        </Grid>
        <Grid item xs={6} container justifyContent="center" sx={{ padding: 2 }}>
          <TextField
            variant="standard"
            label="Campaign Name"
            required
            id="name"
            name="name"
            autoComplete="name"
            autoFocus
            onChange={(e) =>
              setFormInput({ ...formInput, title: e.target.value })
            }
            sx={{ width: 300, marginBottom: 4, padding: 0.5 }}
          />
          <FormControl sx={{ width: 300, marginBottom: 4, padding: 0.5 }}>
            <InputLabel
              id="campaign-type-select-label"
              sx={{ color: "#EF2E83", fontSize: "1rem !important" }}
            >
              Campaign Type
            </InputLabel>
            <Select
              labelId="campaign-type-select-label"
              variant="standard"
              id="campaign-type-select"
              label="Campaign Type"
              value={formInput.campaign_type}
              onChange={(e) =>
                setFormInput({ ...formInput, campaign_type: e.target.value })
              }
            >
              {Campaign_type.map((type) => (
                <MenuItem
                  value={type.value}
                  key={type.value + "campaign-type" + type.value}
                >
                  {type.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {formInput.campaign_type === 1 && (
            <>
              <br />
              <FormControlLabel
                sx={{ width: 300, marginBottom: 4, padding: 0.5 }}
                control={<Checkbox checked={formInput.requiresUsageRights} />}
                onChange={() =>
                  setFormInput({
                    ...formInput,
                    requiresUsageRights: !formInput.requiresUsageRights,
                  })
                }
                label="Usage Right Needed"
              />
              <FormControl
                sx={{ width: 300, marginBottom: 4, padding: 0.5 }}
                disabled={!formInput.requiresUsageRights}
              >
                <InputLabel
                  id="campaign-type-select-label"
                  sx={{ color: "#EF2E83", fontSize: "1rem !important" }}
                >
                  Usage Rights Duration
                </InputLabel>
                <Select
                  labelId="usage-rights-duration-select-label"
                  variant="standard"
                  id="usage-rights-duration-select"
                  label="Campaign Type"
                  value={formInput.usageRightsPeriod}
                  onChange={(e) =>
                    setFormInput({
                      ...formInput,
                      usageRightsPeriod: e.target.value,
                    })
                  }
                >
                  {usageRightsPeriods.map((type) => (
                    <MenuItem
                      value={type.value}
                      key={"usage-rights-duration" + type.value}
                    >
                      {type.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          )}
          <FormControl sx={{ width: 300, marginBottom: 4, padding: 0.5 }}>
            <InputLabel
              id="campaign-category-select-label"
              sx={{ color: "#EF2E83", fontSize: "1rem !important" }}
            >
              Campaign Category
            </InputLabel>
            <Select
              variant="standard"
              labelId="campaign-category-select-label"
              id="campaign-category-select"
              label="Campaign Category"
              value={formInput.category_id}
              onChange={(e) =>
                setFormInput({ ...formInput, category_id: e.target.value })
              }
            >
              {categories?.getCampaignCategories?.map((category) => (
                <MenuItem
                  value={category.id}
                  key={category.id + "category-type" + category.id}
                >
                  {category.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ width: 300, marginBottom: 4, padding: 0.5 }}>
            <InputLabel
              id="demo-simple-select-label"
              sx={{ color: "#EF2E83", fontSize: "1rem !important" }}
            >
              Campaign Media
            </InputLabel>
            <Select
              variant="standard"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Campaign Media"
              onChange={(e) =>
                setFormInput({ ...formInput, campaign_media: e.target.value })
              }
            >
              {Campaign_Media.map((media) => (
                <MenuItem
                  value={media.value}
                  key={media.value + "media-type" + media.value}
                >
                  {media.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ width: 300, marginBottom: 4, padding: 0.5 }}>
            <InputLabel
              id="mutiple-select-label"
              sx={{ color: "#EF2E83", fontSize: "1rem !important" }}
            >
              Age Group
            </InputLabel>
            <Select
              variant="standard"
              labelId="mutiple-select-label"
              multiple
              value={selectedAge}
              onChange={handleAgeChange}
              renderValue={(selected) => selected.join(", ")}
            >
              <MenuItem
                value="all"
                classes={{
                  root: isAllSelected ? classes.selectedAll : "",
                }}
              >
                <ListItemIcon>
                  <Checkbox
                    classes={{ indeterminate: classes.indeterminateColor }}
                    checked={isAllSelected}
                    indeterminate={
                      selectedAge.length > 0 &&
                      selectedAge.length < Age_Group.length
                    }
                  />
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: classes.selectAllText }}
                  primary="Select All"
                />
              </MenuItem>
              {Age_Group.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  <ListItemIcon>
                    <Checkbox
                      checked={selectedAge.indexOf(option.value) > -1}
                    />
                  </ListItemIcon>
                  <ListItemText primary={option.label} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ width: 300, marginBottom: 4, padding: 0.5 }}>
            <InputLabel
              id="mutiple-select-label"
              sx={{ color: "#EF2E83", fontSize: "1rem !important" }}
            >
              No. of Followers
            </InputLabel>
            <Select
              variant="standard"
              labelId="multiple-select-label"
              multiple
              value={selectedFollower}
              onChange={handleFollowerChange}
              renderValue={(selected) => selected.join(", ")}
            >
              <MenuItem
                value="all"
                classes={{
                  root: isAllSelected ? classes.selectedAll : "",
                }}
              >
                <ListItemIcon>
                  <Checkbox
                    classes={{ indeterminate: classes.indeterminateColor }}
                    checked={isFollowerSelected}
                    indeterminate={
                      selectedFollower.length > 0 &&
                      selectedFollower.length < No_of_Followers.length
                    }
                  />
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: classes.selectAllText }}
                  primary="Select All"
                />
              </MenuItem>
              {No_of_Followers.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  <ListItemIcon>
                    <Checkbox
                      checked={selectedFollower.indexOf(option.value) > -1}
                    />
                  </ListItemIcon>
                  <ListItemText primary={option.label} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            variant="standard"
            label="Male Ratio"
            required
            id="male ratio"
            name="male ratio"
            autoComplete="male ratio"
            autoFocus
            onChange={(e) =>
              setFormInput({ ...formInput, male_ratio: e.target.value })
            }
            sx={{ width: 300, marginBottom: 4, padding: 0.5 }}
          />
          <TextField
            variant="standard"
            label="Female Ratio"
            required
            id="female ratio"
            name="female ratio"
            autoComplete="female ratio"
            autoFocus
            onChange={(e) =>
              setFormInput({ ...formInput, female_ratio: e.target.value })
            }
            sx={{ width: 300, marginBottom: 4, padding: 0.5 }}
          />
          <Grid
            container
            spacing={2}
            sx={{ maxWidth: "640px", margin: "auto" }}
          >
            <ThemeProvider theme={theme}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <Grid item xs={12} md={6}>
                  <DatePicker
                    label="Start Date"
                    value={startDate}
                    format="DD/MM/YYYY"
                    onChange={(newValue) => setStartDate(newValue)}
                    slotProps={{
                      textField: { variant: "standard", fullWidth: true },
                      label: { color: "red" },
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DatePicker
                    label="End Date"
                    value={endDate}
                    format="DD/MM/YYYY"
                    onChange={(newValue) => setEndDate(newValue)}
                    slotProps={{
                      textField: { variant: "standard", fullWidth: true },
                    }}
                  />
                </Grid>
              </LocalizationProvider>
            </ThemeProvider>
          </Grid>
          <div
            style={{
              display: "flex",
              marginTop: "20px",
              width: "100%",
              justifyContent: "center",
            }}
          >
            <CurrencyType
              currencyType={currencyType}
              setCurrencyType={setCurrencyType}
              width={"40%"}
            />
            <TextField
              variant="standard"
              label="Campaign Cost"
              required
              id="cost"
              name="cost"
              autoComplete="cost"
              autoFocus
              onChange={(e) =>
                setFormInput({ ...formInput, cost: e.target.value })
              }
              sx={{ width: 300, marginBottom: 4 }}
            />
          </div>
          <Stack
            direction="row"
            sx={{
              flexDirection: "row !important",
              marginTop: "10px",
              marginRight: "10px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <FormControlLabel
              control={<Checkbox checked={formInput.stories} />}
              onChange={() =>
                setFormInput({
                  ...formInput,
                  stories: !formInput.stories,
                })
              }
              label="Stories"
            />
            <FormControlLabel
              control={<Checkbox checked={formInput.reels} />}
              onChange={() =>
                setFormInput({
                  ...formInput,
                  reels: !formInput.reels,
                })
              }
              label="Reels"
            />
            <FormControlLabel
              control={<Checkbox checked={formInput.videos} />}
              onChange={() =>
                setFormInput({
                  ...formInput,
                  videos: !formInput.videos,
                })
              }
              label="TikTok Posts"
            />
            <FormControlLabel
              control={<Checkbox checked={formInput.pictures} />}
              onChange={() =>
                setFormInput({
                  ...formInput,
                  pictures: !formInput.pictures,
                })
              }
              label="Feed Posts"
            />
          </Stack>

          <FormControl
            sx={{
              width: 330,
              marginBottom: 4,
              padding: 0.5,
              marginTop: "40px",
            }}
          >
            <InputLabel
              id="opt-in-for-the-affiliate-commission-model"
              sx={{ color: "#EF2E83", fontSize: "1rem !important" }}
            >
              Opt in for the Affiliate commission model
            </InputLabel>
            <Select
              variant="standard"
              labelId="opt-in-for-the-affiliate-commission-model"
              id="opt-in-for-the-affiliate-commission"
              label="Opt in for the Affiliate commission model"
              value={formInput.commission_model}
              onChange={(e) =>
                setFormInput({ ...formInput, commission_model: e.target.value })
              }
            >
              {[
                { label: "Yes", value: true },
                { label: "No", value: false },
              ].map((option) => (
                <MenuItem
                  value={option.value}
                  key={option.value + "commission-model" + option.value}
                >
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Grid
            item
            xs={12}
            container
            sx={{ marginTop: 2 }}
            justifyContent="center"
            alignItems="center"
            direction="column"
          >
            <TextField
              variant="standard"
              label="Description"
              required
              multiline
              id="description"
              name="description"
              autoComplete="description"
              minRows={5}
              autoFocus
              onChange={(e) =>
                setFormInput({ ...formInput, description: e.target.value })
              }
              sx={{ width: 500, marginBottom: 4, padding: 0.5 }}
            />

            <label>
              <Button
                variant="contained"
                color="primary"
                component="span"
                onClick={() => setOpenPolicyModal(true)}
              >
                Accept Framework Policy
              </Button>
              <span
                style={{
                  marginLeft: "10px",
                  color: isPolicyAccepted ? "green" : "red",
                }}
              >
                {isPolicyAccepted ? "Accepted" : "Not Accepted"}
              </span>
            </label>
          </Grid>

          <ProfilePicUploader setPP={handleImageUpload} source="campaign" />
          <Grid
            item
            xs={12}
            container
            sx={{ maxWidth: "500px", overflow: "scroll" }}
          >
            {formInput?.galleries?.map((image, index) => (
              <img
                src={image}
                alt={"selection-" + index}
                width="80"
                style={{ marginRight: "5px" }}
                key={"image-no-" + index}
              />
            ))}
          </Grid>
          <PinkButton
            variant="contained"
            onClick={() => createCampaign()}
            disabled={
              (formInput.campaign_type === 1 && !agreed) ||
              formInput.galleries.length < 1 || 
              !isPolicyAccepted
            }
            sx={{
              marginTop: 2,
              width: 300,
              backgroundColor:
                formInput.campaign_type === 1 &&
                !agreed &&
                "#BDC3C7 !important",
            }}
          >
            Create Campaign
          </PinkButton>
        </Grid>
        {formInput.campaign_type === 1 && (
          <Grid
            item
            xs={12}
            md={6}
            container
            justifyContent="center"
            sx={{ padding: 2 }}
          >
            <div dangerouslySetInnerHTML={{ __html: defaultContract }} />
            <Button
              disabled={formInput.campaign_type === 0}
              variant="contained"
              onClick={() => setAddExtendedContract((prev) => !prev)}
            >
              Add Additional Clauses
            </Button>
            {addExtendedContract && (
              <Grid item xs={12} sx={{ height: 500, marginTop: 4 }}>
                <Typography variant="h5"> Custom Clauses </Typography>
                <ReactQuill
                  theme="snow"
                  value={extendedContract}
                  onChange={setExtendedContract}
                  style={{ height: 400 }}
                />
              </Grid>
            )}
            <FormControlLabel
              control={
                <Checkbox
                  disabled={formInput.campaign_type === 0}
                  checked={agreed}
                  onChange={handleAgreement}
                  inputProps={{ "aria-label": "controlled" }}
                  sx={{
                    color: pink[800],
                    "&.Mui-checked": {
                      color: pink[600],
                    },
                  }}
                />
              }
              label="I have read the terms and agree."
              sx={{
                width: "100%",
                justifyContent: "center",
                backgroundColor: "#000",
                paddingLeft: 3,
                paddingRight: 3,
                marginTop: 2,
                color: "#fff",
              }}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default AddCampaigns;

import { createStyles, makeStyles } from "@mui/styles";

const styles = makeStyles((theme) =>
  createStyles({
    card: {
      maxWidth: 215,
      minWidth: 215,
      transition: "0.3s",
      boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
      "&:hover": {
        boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)"
      }
    },
    media: {
      paddingTop: "56.25%"
    },
    content: {
      textAlign: "left",
      paddingTop: "5px !important",
      paddingBottom: "10px !important"
    },
    divider: {
      margin: `3px 0`
    },
    heading: {
      fontWeight: "bold"
    },
    subheading: {
      lineHeight: 1.8
    },
    avatar: {
      display: "inline-block",
      border: "2px solid white",
      "&:not(:first-of-type)": {
        marginLeft: -15
      }
    }
  })
);

export default styles;

import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import {
    Box,
    Button,
    Grid,
    Tabs,
    Tab,
    Typography,
    ListItemAvatar,
    Avatar,
    ListItemText,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    TextField,
    DialogActions,
    Accordion,
    AccordionSummary,
    List,
    ListItem,
    AccordionDetails,
    Container,
    CircularProgress,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import CheckIcon from "@mui/icons-material/Check";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {useMutation, useQuery} from "@apollo/client";
import GET_INTERESTED_INFLUENCERS from "../../../graphql/queries/getInterestedInfluencers";
import GET_INVITED_INFLUENCERS from "../../../graphql/queries/getInvitedInfluencers";
import InfluencerPopup from "../../common-components/influencerPopup";
import NEGOTIATE_PRICE from "../../../graphql/mutations/negotiatePrice";
import ACCEPT_OFFER from "../../../graphql/mutations/brandAcceptContract";
import REJECT_OFFER from "../../../graphql/mutations/brandRejectContract";
import ACCEPT_GIFTED_OFFER from "../../../graphql/mutations/brandAcceptGiftedContract";
import {getCurrencySymbol} from "../../sharedComponents/currencies";
import CurrencyType from "../../sharedComponents/CurrencyType";
import UPDATE_INFLUENCER_CONTRACT from "../../../graphql/mutations/updateInfluencerContract";
import {toast} from "react-toastify";
import S3 from "aws-s3";
import s3_config from "../../../config/s3_config";
import ReactQuill from "react-quill";
import SHOW_PAID_INTEREST from "../../../graphql/mutations/interestedInPaidCampaign";
import GET_BRAND from "../../../graphql/queries/getBrandInfo";
import ConfirmDelete from "./confirmDelete";
import SAVED_SIGNATURE from "../../../graphql/queries/getSavedSignature";

const TabPanel = (props) => {
    const {children, value, index, ...other} = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`influencer-tabpanel-${index}`}
            aria-labelledby={`influencer-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
};

const InfluencerListing = ({
                               campaignId,
                               setInfluencerCount,
                               campaignType,
                               campaign,
                           }) => {
    const [selectedTab, setSelectedTab] = useState(0);

    const [negotiate, openNegotiate] = useState(false);
    const [influencerToShow, setInfluencerToShow] = useState(null);
    const [influencerToNegotiate, setInfluencerToNegotiate] = useState(null);
    const [defaultContract, setDefaultContract] = useState();
    const [influencerContract, setInfluencerContract] = useState({});
    const [campaignContract, setCampaignContract] = useState();
    const [editContract, showEditContract] = useState(false);
    const [contract, setContract] = useState();

    // State for default contract and signatures
    const [brandSignature, setBrandSignature] = useState(null);
    const [brandSignature1, setBrandSignature1] = useState(null);
    const [brandName, setBrandName] = useState("");
    const [brandDate, setBrandDate] = useState("");

    // Example state variables causing ESLint errors
    const [myQuote, setMyQuote] = useState(0);
    const [currencyType, setCurrencyType] = useState("");
    const [myDescription, setDescription] = useState("");
    const [influencerCommissionModel, setInfluencerCommissionModel] =
        useState(false);
    const [openInterestModal, setOpenInterestModal] = useState(false);
    const [remove, setRemove] = useState(true);
    // State to track submission state
    const [submitting, setSubmitting] = useState(false);

    // Query to fetch brand data
    const {
        loading: brandLoading,
        error: brandError,
        data: brandData,
        refetch: refetchBrand,
    } = useQuery(GET_BRAND, {
        variables: {
            userId: localStorage.getItem("currentUser"),
            campaignId: campaignId,
            influencerId: localStorage.getItem("currentUser"),
        },
        onCompleted: (data) => {
            setBrandSignature1(data.getBrand.signature);
        },
        onError: (error) => {
            console.error("Error fetching brand data:", error);
        },
    });

    // Function to handle adding signature
    const handleAddSignature = (type) => {
        if (type === "brand") {
            setRemove(false);
            setBrandSignature(brandSignature1);
        }
    };

    // Function to handle removing signatures
    const handleRemoveSignature = (type) => {
        if (type === "brand") setRemove(false);
        setBrandSignature(null);
    };
    const loadContract = async () => {
        await fetch(
            "https://authentic-pps.s3.eu-west-2.amazonaws.com/authentic_default_contract.txt"
        ).then((r) => {
            r.text().then((d) => {
                setDefaultContract(d);
            });
        });
    };

    const loadCampaignContract = async () => {
        await fetch(campaign.contract).then((r) => {
            r.text().then((d) => {
                setCampaignContract(d);
            });
        });
    };

    const fetchContracts = async () => {
        const contractHTMLs = {};
        if (interestedInfluencers) {
            for (const row of interestedInfluencers?.getInterestedInfluencers) {
                try {
                    const text = await loadInfluencerContract(row.customContract);
                    contractHTMLs[row.id] = text;
                } catch (error) {
                    contractHTMLs[row.id] = "Error loading contract.";
                }
            }
        }
        if (invitedInfluencers) {
            for (const row of invitedInfluencers?.getInvitedInfluencers) {
                try {
                    const text = await loadInfluencerContract(row.customContract);
                    contractHTMLs[row.id] = text;
                } catch (error) {
                    contractHTMLs[row.id] = "Error loading contract.";
                }
            }
        }
        setInfluencerContract(contractHTMLs);
    };

    async function loadInfluencerContract(contractLink) {
        try {
            const response = await fetch(contractLink);
            const data = await response.text();
            return data;
        } catch (error) {
            console.error("Error loading contract:", error);
        }
    }

    useEffect(() => {
        if (campaignType === "Paid") {
            loadContract();
            loadCampaignContract();
        }
    }, [campaignType]);

    const {data: interestedInfluencers} = useQuery(GET_INTERESTED_INFLUENCERS, {
        variables: {campaignId: campaignId},
        onCompleted: (res) => {
            setInfluencerCount(res.getInterestedInfluencers.length);
        },
    });

    const {data: invitedInfluencers} = useQuery(GET_INVITED_INFLUENCERS, {
        variables: {
            campaignId: campaignId,
        },
    });
    useEffect(() => {
        fetchContracts();
    }, [interestedInfluencers, invitedInfluencers]);
    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };
    const handleNegotiate = (influencerId) => {
        setInfluencerToNegotiate(influencerId);
        openNegotiate(true);
    };
    const [acceptOffer] = useMutation(ACCEPT_OFFER);
    const [acceptGiftedOffer] = useMutation(ACCEPT_GIFTED_OFFER);
    const [rejectOffer] = useMutation(REJECT_OFFER);
    // Mutation to show interest and save signatures
    const [showInterest, {loading: interestLoading}] = useMutation(
        SHOW_PAID_INTEREST,
        {
            onCompleted: () => {
                setMyQuote(0);
                setCurrencyType("");
                setDescription("");
                setOpenInterestModal(false);
                setSubmitting(false); // Reset submitting state after completion
            },
            refetchQueries: [
                "getCandidateCampaignsForInfluencer",
                "getInterestedCampaignsForInfluencer",
                "getInvitedCampaignsForInfluencer",
                "getCampaignsForInfluencer",
            ],
            onError: (error) => {
                console.error("Error showing interest:", error);
                setSubmitting(false);
            },
        }
    );

    const handleContractAccept = (
        contractId,
        brandQ,
        influencerQ,
        influencerId
    ) => {
        if (campaignType === "Paid") {
            acceptOffer({
                variables: {
                    contractId: contractId,
                    influencerId: influencerId,
                    finalFee: brandQ === null ? influencerQ : brandQ,
                },
                refetchQueries: [
                    "getPreInvoice",
                    "getInterestedInfluencers",
                    "getInvitedInfluencers",
                    "getContractedInfluencers",
                ],
            });
        } else {
            acceptGiftedOffer({
                variables: {
                    contractId: contractId,
                    influencerId: influencerId,
                },
                refetchQueries: [
                    "getPreInvoice",
                    "getInterestedInfluencers",
                    "getInvitedInfluencers",
                    "getContractedInfluencers",
                ],
            });
        }
    };

    const handleContractReject = (contractId, influencerId) => {
        rejectOffer({
            variables: {
                contractId: contractId,
                influencerId: influencerId,
            },
            refetchQueries: ["getInterestedInfluencers", "getInvitedInfluencers"],
        });
    };

    const [expanded, setExpanded] = useState(false);
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const [deleteConfirmation, showDeleteConfirmation] = useState(false);
    const [deleteInfluencer, setDeleteInfluencer] = useState();
    const [deleteContractId, setDeleteContractId] = useState();

    const toggleDeleteConfirmation = (id, influencerId, type) => {
        showDeleteConfirmation(!deleteConfirmation);
        setDeleteContractId(id);

        const contracts = type === "interested"
            ? interestedInfluencers?.getInterestedInfluencers
            : invitedInfluencers?.getInvitedInfluencers;

        const contract = contracts?.find(contract => contract.id === id);
        setDeleteInfluencer(contract?.influencer);
    };

    const getContract = (influencer) => {
        if (influencer.customContract) {
            return influencerContract[influencer.id];
        } else {
            return null;
        }
    };

    const getOfer = (row) => {
        return row.influencerQuoteType
            ? getCurrencySymbol(row.influencerQuoteType) + row.influencerQuote
            : row.influencerQuote;
    };

    const getMyOfer = (row) => {
        return row.brandQuoteType
            ? getCurrencySymbol(row.brandQuoteType) + row.brandQuote
            : row.brandQuote;
    };

    const handleEditContract = (row) => {
        setContract(row);
        showEditContract(true);
    };
    const handleSignature = (row) => {
        const newBrandSignature = row.brandSignature || brandSignature1;
        const newBrandName = row.brandName || brandName;
        const newBrandDate = row.brandDate || brandDate;

        setBrandSignature(newBrandSignature);
        setBrandName(newBrandName);
        setBrandDate(newBrandDate);

        handleSubmitSignatures(
            row.influencer.id,
            newBrandSignature,
            newBrandName,
            newBrandDate
        );
    };

    const handleSubmitSignatures = (
        influencerId,
        newBrandSignature,
        newBrandName,
        newBrandDate
    ) => {
        setSubmitting(true); // Set submitting state to true
        // Perform mutation to save signatures and show interest
        showInterest({
            variables: {
                userId: influencerId,
                campaignId: campaignId,
                influencerQuote: parseInt(myQuote),
                influencerQuoteType: currencyType,
                description: myDescription,
                commissionModel: influencerCommissionModel,
                brandSignature: newBrandSignature,
                brandName: newBrandName,
                brandDate: newBrandDate,
            },
        }).finally(() => {
            setSubmitting(false); // Reset submitting state
        });
    };

    if (brandLoading || interestLoading) {
        return (
            <Container style={{textAlign: "center", marginTop: "20px"}}>
                <CircularProgress/>
            </Container>
        );
    }
    return (
        <Grid
            item
            xs={12}
            container
            justifyContent="center"
            sx={{marginBottom: 2}}
        >
            <Grid
                item
                xs={12}
                container
                justifyContent="space-between"
                alignItems="center"
            >
                <Tabs
                    value={selectedTab}
                    onChange={handleTabChange}
                    aria-label="influencer-listing-switch"
                    sx={{maxWidth: 450}}
                >
                    <Tab label="Interested Influencers"/>
                    <Tab label="Invited Influencers"/>
                </Tabs>
                <Link
                    to="/influencer"
                    style={{
                        textDecoration: "none",
                        color: "#FFA3C7",
                        fontWeight: 700,
                        marginRight: 5,
                        fontSize: "1.4rem",
                    }}
                >
                    Choose Influencers
                </Link>
            </Grid>
            <Grid item xs={12}>
                <TabPanel value={selectedTab} index={0}>
                    {interestedInfluencers?.getInterestedInfluencers?.map(
                        (row, index) => (
                            <Accordion
                                key={"influencer-metric" + row?.id}
                                expanded={expanded === "panel" + row?.id}
                                onChange={handleChange("panel" + row?.id)}
                                sx={{width: "100%"}}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon/>}
                                    aria-controls={row?.influencer?.name + "-metrics"}
                                    id={row?.influencer?.name + "-metrics"}
                                    sx={{
                                        alignItems: "center",
                                        backgroundColor:
                                            row.contractStatus === "Finalized"
                                                ? "rgba(46, 204, 113,0.5)"
                                                : row.contractStatus === "Cancelled"
                                                    ? "rgba(216, 31, 42, 0.6)"
                                                    : "background.paper",
                                        color: row.contractStatus === "Cancelled" ? "#FFFFFF" : "",
                                    }}
                                >
                                    <List
                                        dense
                                        sx={{
                                            width: "90%",
                                            backgroundColor: "transparent",
                                            display: "inline-flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <ListItemAvatar>
                                            <Avatar
                                                alt=""
                                                src={
                                                    row?.influencer?.image ||
                                                    "https://mir-s3-cdn-cf.behance.net/user/230/110659178842.5c1b604bb2cea.jpg"
                                                }
                                            />
                                        </ListItemAvatar>
                                        <ListItemText
                                            id={row?.id}
                                            primary={row?.influencer?.name}
                                            primaryTypographyProps={{
                                                textAlign: "center",
                                                fontWeight: 700,
                                            }}
                                            sx={{width: 180}}
                                        />
                                        {campaignType !== "Gifted" && (
                                            <ListItemText
                                                id={row?.id}
                                                primary="Influencer Offer"
                                                secondary={getOfer(row) || "-"}
                                                primaryTypographyProps={{
                                                    textAlign: "center",
                                                    fontWeight: 700,
                                                }}
                                                secondaryTypographyProps={{
                                                    textAlign: "center",
                                                    color: "rgba(239, 46, 131, 1)",
                                                }}
                                                sx={{width: 180}}
                                            />
                                        )}
                                        {campaignType !== "Gifted" && (
                                            <ListItemText
                                                id={row?.id}
                                                primary="My Offer"
                                                secondary={getMyOfer(row) || "-"}
                                                primaryTypographyProps={{
                                                    textAlign: "center",
                                                    fontWeight: 700,
                                                }}
                                                secondaryTypographyProps={{
                                                    textAlign: "center",
                                                    color: "rgba(239, 46, 131, 1)",
                                                }}
                                                sx={{width: 180}}
                                            />
                                        )}
                                        {row.brandQuote && row.influencerQuote && (
                                            <ListItemText
                                                id={row?.id}
                                                primary="Usage Rights"
                                                secondary={"90 Days" || "-"}
                                                primaryTypographyProps={{
                                                    textAlign: "center",
                                                    fontWeight: 700,
                                                }}
                                                secondaryTypographyProps={{
                                                    textAlign: "center",
                                                    color: "rgba(239, 46, 131, 1)",
                                                }}
                                                sx={{width: 180}}
                                            />
                                        )}
                                        <ListItem sx={{width: 180}}>
                                            <Button
                                                variant="contained"
                                                color="success"
                                                onClick={() =>
                                                    handleContractAccept(
                                                        row.id,
                                                        row.brandQuote,
                                                        row.influencerQuote,
                                                        row.influencer?.id
                                                    )
                                                }
                                                disabled={
                                                    (!row.influencerQuote && campaignType !== 'Gifted') ||
                                                    row.contractStatus === "Finalized"
                                                }
                                            >
                                                <CheckIcon/>
                                            </Button>
                                        </ListItem>
                                        <ListItem sx={{width: 180}}>
                                            <Button
                                                variant="contained"
                                                color="error"
                                                onClick={() =>
                                                    handleContractReject(row.id, row?.influencer?.id)
                                                }
                                                disabled={row.contractStatus === "Finalized"}
                                            >
                                                <ClearIcon/>
                                            </Button>
                                        </ListItem>
                                        <ListItem sx={{width: 180}}>
                                            <Button
                                                variant="contained"
                                                onClick={() => handleNegotiate(row?.influencer?.id)}
                                                disabled={
                                                    row.currentFlow > 4 ||
                                                    row.currentNegotiator == "brand" ||
                                                    campaignType === "Gifted" ||
                                                    row.contractStatus === "Finalized" ||
                                                    row.contractStatus === "Cancelled"
                                                }
                                            >
                                                Negotiate
                                            </Button>
                                        </ListItem>
                                        <ListItem sx={{width: 180}}>
                                            <Button
                                                variant="contained"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setInfluencerToShow(row?.influencer?.id)
                                                }}
                                            >
                                                Details
                                            </Button>
                                        </ListItem>

                                        <ListItem sx={{width: 180}}>
                                            <Button
                                                variant="contained"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    toggleDeleteConfirmation(row.id, row?.influencer?.id, "interested");
                                                }}
                                            >
                                                Delete
                                            </Button>

                                        </ListItem>

                                        <ListItem sx={{width: 180}}>
                                            <Button
                                                variant="contained"
                                                size="small"
                                                disabled={!row.customContract}
                                                onClick={() => {
                                                    handleEditContract(row);
                                                }}
                                            >
                                                Edit Contract
                                            </Button>
                                        </ListItem>
                                    </List>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography
                                        variant="subtitle1"
                                        color="#FFF"
                                        paddingX={2}
                                        sx={{backgroundColor: "#FFA3C7", borderRadius: 2}}
                                    >
                                        {row.description}
                                    </Typography>
                                    {campaignType === "Paid" && (
                                        <>
                                            <div
                                                dangerouslySetInnerHTML={
                                                    {__html: defaultContract} || {
                                                        __html: "Loading...",
                                                    }
                                                }
                                            />

                                            {campaignContract && (
                                                <div>
                                                    <b>
                                                        <br/>
                                                        Additional Clauses by brand
                                                    </b>
                                                    <div
                                                        dangerouslySetInnerHTML={
                                                            {__html: campaignContract} || {
                                                                __html: "Loading...",
                                                            }
                                                        }
                                                    />
                                                </div>
                                            )}

                                            {getContract(row) && (
                                                <div>
                                                    <b>
                                                        <br/>
                                                        Additional Clauses by influencer
                                                    </b>
                                                    <div
                                                        dangerouslySetInnerHTML={
                                                            {__html: getContract(row)} || {
                                                                __html: "Loading...",
                                                            }
                                                        }
                                                    />
                                                </div>
                                            )}
                                            {/* Display Signatures */}
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-around",
                                                    alignItems: "flex-start",
                                                }}
                                            >
                                                {/* Brand Signature */}
                                                <div style={{textAlign: "center", width: "30%"}}>
                                                    <Typography variant="subtitle2">
                                                        Brand Signature
                                                    </Typography>
                                                    <div
                                                        style={{
                                                            height: 150,
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        {row?.brandSignature && remove ? (
                                                            <>
                                                                <img
                                                                    src={row?.brandSignature}
                                                                    alt="Brand Signature"
                                                                    style={{
                                                                        maxWidth: 150,
                                                                        maxHeight: 150,
                                                                        marginBottom: 10,
                                                                    }}
                                                                />
                                                            </>
                                                        ) : brandSignature ? (
                                                            <>
                                                                <img
                                                                    src={brandSignature}
                                                                    alt="Brand Signature"
                                                                    style={{
                                                                        maxWidth: 150,
                                                                        maxHeight: 150,
                                                                        marginBottom: 10,
                                                                    }}
                                                                />
                                                                <Button
                                                                    onClick={() => handleRemoveSignature("brand")}
                                                                >
                                                                    Remove
                                                                </Button>
                                                            </>
                                                        ) : (
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                onClick={() => handleAddSignature("brand")}
                                                            >
                                                                Add Signature
                                                            </Button>
                                                        )}
                                                    </div>
                                                    {(row?.brandSignature || brandSignature) && (
                                                        <>
                                                            <TextField
                                                                id="brand-name"
                                                                label="Name"
                                                                variant="standard"
                                                                value={brandName ? brandName : row?.brandName}
                                                                onChange={(e) => setBrandName(e.target.value)}
                                                                style={{display: "block", marginBottom: 10}}
                                                            />
                                                            <TextField
                                                                id="brand-date"
                                                                label="Date"
                                                                type="date"
                                                                variant="standard"
                                                                value={brandDate ? brandDate : row?.brandDate}
                                                                onChange={(e) => setBrandDate(e.target.value)}
                                                                style={{display: "block", marginBottom: 10}}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                            />
                                                        </>
                                                    )}
                                                </div>

                                                {/* Influencer Signature */}
                                                <div style={{textAlign: "center", width: "30%"}}>
                                                    <Typography variant="subtitle2">
                                                        Influencer Signature
                                                    </Typography>
                                                    <div
                                                        style={{
                                                            height: 150,
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        {row?.influencerSignature ? (
                                                            <>
                                                                <img
                                                                    src={row?.influencerSignature}
                                                                    alt="Influencer Signature"
                                                                    style={{
                                                                        maxWidth: 150,
                                                                        maxHeight: 150,
                                                                        marginBottom: 10,
                                                                    }}
                                                                />
                                                                {/* <Button onClick={() => handleRemoveSignature('influencer')}>Remove</Button> */}
                                                            </>
                                                        ) : (
                                                            // <Button variant="contained" color="primary" onClick={() => handleAddSignature('influencer')}>
                                                            //   Add Signature
                                                            // </Button>
                                                            <label>No signature</label>
                                                        )}
                                                    </div>
                                                    {row?.influencerSignature && (
                                                        <>
                                                            <TextField
                                                                id="influencer-name"
                                                                label="Name"
                                                                variant="standard"
                                                                value={row?.influencerName}
                                                                style={{display: "block", marginBottom: 10}}
                                                            />
                                                            <TextField
                                                                id="influencer-date"
                                                                label="Date"
                                                                type="date"
                                                                variant="standard"
                                                                value={row?.influencerDate}
                                                                style={{display: "block", marginBottom: 10}}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                            />
                                                        </>
                                                    )}
                                                </div>

                                                {/* Admin Signature */}
                                                <div style={{textAlign: "center", width: "30%"}}>
                                                    <Typography variant="subtitle2">
                                                        Admin Signature
                                                    </Typography>
                                                    <div
                                                        style={{
                                                            height: 150,
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        {row?.adminSignature ? (
                                                            <>
                                                                <img
                                                                    src={row?.adminSignature}
                                                                    alt="Admin Signature"
                                                                    style={{
                                                                        maxWidth: 150,
                                                                        maxHeight: 150,
                                                                        marginBottom: 10,
                                                                    }}
                                                                />
                                                                {/* <Button onClick={() => handleRemoveSignature('admin')}>Remove</Button> */}
                                                            </>
                                                        ) : (
                                                            // <Button variant="contained" color="primary" onClick={() => handleAddSignature('admin')}>
                                                            //   Add Signature
                                                            // </Button>
                                                            <label>No signature</label>
                                                        )}
                                                    </div>
                                                    {row?.adminSignature && (
                                                        <>
                                                            <TextField
                                                                id="admin-name"
                                                                label="Name"
                                                                variant="standard"
                                                                value={row?.adminName}
                                                                style={{display: "block", marginBottom: 10}}
                                                            />
                                                            <TextField
                                                                id="admin-date"
                                                                label="Date"
                                                                type="date"
                                                                variant="standard"
                                                                value={row?.adminDate}
                                                                style={{display: "block", marginBottom: 10}}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                            />
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                            {/* Submit Button */}
                                            <div style={{textAlign: "center", marginTop: 20}}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => handleSignature(row)}
                                                    disabled={submitting}
                                                    style={{minWidth: 200}}
                                                >
                                                    {submitting ? (
                                                        <CircularProgress size={24}/>
                                                    ) : (
                                                        "Submit"
                                                    )}
                                                </Button>
                                            </div>
                                        </>
                                    )}
                                </AccordionDetails>
                            </Accordion>
                        )
                    )}
                </TabPanel>
                <TabPanel value={selectedTab} index={1}>
                    {invitedInfluencers?.getInvitedInfluencers?.map((row, index) => (
                        <Accordion
                            key={"influencer-metric" + row?.id}
                            expanded={expanded === "panel" + row?.id}
                            onChange={
                                campaignType === "Paid" && handleChange("panel" + row?.id)
                            }
                            sx={{width: "100%"}}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls={row?.influencer?.name + "-metrics"}
                                id={row?.influencer?.name + "-metrics"}
                                sx={{
                                    alignItems: "center",
                                    backgroundColor:
                                        row.contractStatus === "Finalized"
                                            ? "rgba(46, 204, 113,0.5)"
                                            : row.contractStatus === "Cancelled"
                                                ? "rgba(216, 31, 42, 0.6)"
                                                : "background.paper",
                                    color: row.contractStatus === "Cancelled" ? "#FFFFFF" : "",
                                }}
                            >
                                <List
                                    dense
                                    sx={{
                                        width: "90%",
                                        backgroundColor: "transparent",
                                        display: "inline-flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <ListItemAvatar>
                                        <Avatar
                                            alt=""
                                            src={
                                                row?.influencer?.image ||
                                                "https://mir-s3-cdn-cf.behance.net/user/230/110659178842.5c1b604bb2cea.jpg"
                                            }
                                        />
                                    </ListItemAvatar>
                                    <ListItemText
                                        id={row?.id}
                                        primary={row?.influencer?.name}
                                        primaryTypographyProps={{
                                            textAlign: "center",
                                            fontWeight: 700,
                                        }}
                                        sx={{width: 180}}
                                    />
                                    {campaignType === "Paid" && (
                                        <ListItemText
                                            id={row?.id}
                                            primary="Influencer Offer"
                                            secondary={getOfer(row) || "-"}
                                            primaryTypographyProps={{
                                                textAlign: "center",
                                                fontWeight: 700,
                                            }}
                                            secondaryTypographyProps={{
                                                textAlign: "center",
                                                color: "rgba(239, 46, 131, 1)",
                                            }}
                                            sx={{width: 180}}
                                        />
                                    )}
                                    {!row.finalFee && campaignType === "Paid" && (
                                        <ListItemText
                                            id={row?.id}
                                            primary="My Offer"
                                            secondary={getMyOfer(row) || "-"}
                                            primaryTypographyProps={{
                                                textAlign: "center",
                                                fontWeight: 700,
                                            }}
                                            secondaryTypographyProps={{
                                                textAlign: "center",
                                                color: "rgba(239, 46, 131, 1)",
                                            }}
                                            sx={{width: 180}}
                                        />
                                    )}
                                    {row.finalFee && campaignType === "Paid" && (
                                        <ListItemText
                                            id={row?.id}
                                            primary="Finalized Price"
                                            secondary={row.finalFee}
                                            primaryTypographyProps={{
                                                textAlign: "center",
                                                fontWeight: 700,
                                            }}
                                            secondaryTypographyProps={{
                                                textAlign: "center",
                                                color: "rgba(239, 46, 131, 1)",
                                            }}
                                            sx={{width: 180}}
                                        />
                                    )}
                                    <ListItem sx={{width: 180}}>
                                        <Button
                                            variant="contained"
                                            color="success"
                                            onClick={() =>
                                                handleContractAccept(
                                                    row.id,
                                                    row.brandQuote,
                                                    row.influencerQuote,
                                                    row.influencer?.id
                                                )
                                            }
                                            disabled={
                                                !row.influencerQuote ||
                                                row.contractStatus === "Finalized"
                                            }
                                        >
                                            <CheckIcon/>
                                        </Button>
                                    </ListItem>
                                    <ListItem sx={{width: 180}}>
                                        <Button
                                            variant="contained"
                                            color="error"
                                            onClick={() =>
                                                handleContractReject(row.id, row?.influencer?.id)
                                            }
                                            disabled={
                                                !row.influencerQuote ||
                                                row.contractStatus === "Finalized"
                                            }
                                        >
                                            <ClearIcon/>
                                        </Button>
                                    </ListItem>
                                    <ListItem sx={{width: 180}}>
                                        <Button
                                            variant="contained"
                                            onClick={() => handleNegotiate(row?.influencer?.id)}
                                            disabled={
                                                row.currentFlow > 4 ||
                                                row.currentNegotiator == "brand" ||
                                                !row.influencerQuote ||
                                                row.contractStatus === "Finalized"
                                            }
                                        >
                                            Negotiate
                                        </Button>
                                    </ListItem>
                                    <ListItem sx={{width: 180}}>
                                        <Button
                                            variant="contained"
                                            onClick={() => setInfluencerToShow(row?.influencer?.id)}
                                        >
                                            Details
                                        </Button>
                                    </ListItem>

                                    <ListItem sx={{width: 180}}>
                                        <Button
                                            variant="contained"
                                            onClick={() =>
                                                toggleDeleteConfirmation(
                                                    row?.id,
                                                    row?.influencer?.id,
                                                    "invited"
                                                )
                                            }
                                        >
                                            Delete
                                        </Button>
                                    </ListItem>

                                    <ListItem sx={{width: 180}}>
                                        <Button
                                            variant="contained"
                                            size="small"
                                            disabled={!row.customContract}
                                            onClick={() => {
                                                handleEditContract(row);
                                            }}
                                        >
                                            Edit Contract
                                        </Button>
                                    </ListItem>
                                </List>
                            </AccordionSummary>
                            <AccordionDetails>
                                <>
                                    <div
                                        dangerouslySetInnerHTML={
                                            {__html: defaultContract} || {__html: "Loading..."}
                                        }
                                    />

                                    {campaignContract && (
                                        <div>
                                            <b>
                                                <br/>
                                                Additional Clauses by brand
                                            </b>
                                            <div
                                                dangerouslySetInnerHTML={
                                                    {__html: campaignContract} || {
                                                        __html: "Loading...",
                                                    }
                                                }
                                            />
                                        </div>
                                    )}

                                    {getContract(row) && (
                                        <div>
                                            <b>
                                                <br/>
                                                Additional Clauses by influencer
                                            </b>
                                            <div
                                                dangerouslySetInnerHTML={
                                                    {__html: getContract(row)} || {
                                                        __html: "Loading...",
                                                    }
                                                }
                                            />
                                        </div>
                                    )}
                                </>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </TabPanel>
            </Grid>
            {influencerToShow && (
                <InfluencerPopup
                    influencerId={influencerToShow}
                    nextInfluencer={() => console.log("I will do something")}
                    closePopup={() => setInfluencerToShow(null)}
                    campaignId={campaignId}
                    originCampaign={true}
                />
            )}
            {negotiate && (
                <FormDialog
                    open={negotiate}
                    handleClose={openNegotiate}
                    influencerToNegotiate={influencerToNegotiate}
                    campaignId={campaignId}
                />
            )}
            <ConfirmDelete
                deleteConfirmation={deleteConfirmation}
                showDeleteConfirmation={showDeleteConfirmation}
                deleteInfluencer={deleteInfluencer}
                setDeleteInfluencer={setDeleteInfluencer}
                contractId={deleteContractId}
            />

            <EditContractDialog
                editContract={editContract}
                handleClose={showEditContract}
                contract={contract}
            />
        </Grid>
    );
};

export default InfluencerListing;

const FormDialog = ({
                        open,
                        handleClose,
                        campaignId,
                        influencerToNegotiate,
                        ...props
                    }) => {
    const [val, setVal] = useState(0);
    const [brandQuoteType, setBrandQuoteType] = useState("USD");
    const [negotiate] = useMutation(NEGOTIATE_PRICE, {
        refetchQueries: ["getInvitedInfluencers", "getInterestedInfluencers"],
    });

    const send = () => {
        negotiate({
            variables: {
                userId: localStorage.getItem("currentUser"),
                campaignId: campaignId,
                myPrice: parseInt(val),
                brandQuoteType: brandQuoteType,
                influencerId: influencerToNegotiate,
            },
        });
        handleClose(false);
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            {...props}
        >
            <DialogTitle id="form-dialog-title">Negotiate</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Enter a counter offer for the influencer
                </DialogContentText>
                <div style={{display: "flex", marginTop: "20px"}}>
                    <CurrencyType
                        currencyType={brandQuoteType}
                        setCurrencyType={setBrandQuoteType}
                        width={"40%"}
                    />
                    <TextField
                        variant="standard"
                        autoFocus
                        id="name"
                        label="Offer"
                        type="number"
                        onChange={(e) => setVal(e.target.value)}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose(false)} color="primary">
                    Cancel
                </Button>
                <Button onClick={send} color="primary">
                    Send
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const EditContractDialog = ({
                                editContract,
                                handleClose,
                                contract,
                                ...props
                            }) => {
    const [extendedContract, setExtendedContract] = useState();
    const [updateInfluencerContract] = useMutation(UPDATE_INFLUENCER_CONTRACT, {
        onCompleted: () => {
            handleClose(false);
            toast.success("Contract Updated Successfully");
        },
        refetchQueries: ["getInvitedInfluencers", "getInterestedInfluencers"],
    });

    const loadCampaignContract = async () => {
        await fetch(contract?.customContract).then((r) => {
            r.text().then((d) => {
                setExtendedContract(d);
            });
        });
    };

    useEffect(() => {
        loadCampaignContract(contract);
    }, [contract]);

    const send = () => {
        const blob = new Blob([extendedContract], {type: "text/plain"});
        const S3Client = new S3(s3_config);

        S3Client.uploadFile(blob)
            .then((data) => {
                updateInfluencerContract({
                    variables: {
                        contractId: contract.id,
                        contract: data.location,
                    },
                });
            })
            .catch((err) => {
            });
    };

    return (
        <Dialog
            open={editContract}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">Edit Contract</DialogTitle>
            <DialogContent>
                <Grid item xs={12} sx={{height: 500, width: 650}}>
                    <ReactQuill
                        theme="snow"
                        value={extendedContract}
                        onChange={setExtendedContract}
                        style={{height: 400}}
                    />
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose(false)} color="primary">
                    Cancel
                </Button>
                <Button onClick={send} color="primary">
                    Send
                </Button>
            </DialogActions>
        </Dialog>
    );
};

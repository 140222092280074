import {
  Grid,
  FormControl,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import useWindowDimensions from "../CustomHooks/windowDimensions";
import { MenuProps, useStyles, options } from "./utils";
import { useQuery } from "@apollo/client";
import GET_ALL_CAMPAIGNS from "../../graphql/queries/getAllCampaigns";
import GET_INFLUENCER_FOR_GRAPH from "../../graphql/queries/getInfluencerForGraph"
import CampaignSelectDropdown from "./../sharedComponents/campaignSelectDropdown";
import BrandSelectDropdown from "../sharedComponents/brandSelectDropdown";
import GET_BRANDS from "../../graphql/queries/getBrands";
import PublishButton from '../sharedComponents/publishButton';
import UnpublishButton from "../sharedComponents/unpublishButton";
import moment from 'moment';

const CerComparisonChart = (props) => {
  const classes = useStyles();
  const { height, width } = useWindowDimensions();
  const [series, setSeries] = useState([]);
  const [updatedOptions, setUpdatedOptions] = useState({});
  const [selected, setSelected] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [selectedCampaignIds, setSelectedCampaignIds] = useState([]);
  const [campaignData, setCampaignData] = useState(null);
  const [influencers, setInfluencers] = useState([])
  const { userRole, chart } = props;
  const [brands, setBrands] = useState([]);
  const currentUserId = localStorage.getItem('currentUser');
  const [selectedBrand, setSelectedBrand] = useState(currentUserId);

  const { allCampaignsData, refetch: refetchCampaigns } = useQuery(GET_ALL_CAMPAIGNS, {
    variables: {
     userId: selectedBrand,
     userRole: userRole,
     graphId: chart?.id
    },
    onCompleted: (res) => {
      setCampaigns(res.getAllCampaigns);
    }
  });

  const { data: allBrandsData, ...queryRest } = useQuery(GET_BRANDS, {
    skip: userRole !== 'Admin',
    onCompleted: (response) => {
      if (userRole === 'Admin') {
        setBrands(response.getBrands);
      }
    },
  });

  function formatResult(result) {
    if (isNaN(result) || !isFinite(result)) {
      return 0;
    } else {
      return result.toFixed(2);
    }
  }

  const { data, refetch: refetchInfluencers} = useQuery(GET_INFLUENCER_FOR_GRAPH, {
    fetchPolicy: 'network-only',
    variables: {
      campaignIds: selectedCampaignIds,
    },
    onCompleted: (response) => {
      if (response) {
        setInfluencers(response?.getInfluencersForCampaigns)
        let campaignMap = {};

        response?.getInfluencersForCampaigns.forEach(influencer => {
          const instagramFollowers = influencer.instagramStat ? influencer.instagramStat.following : 0;
          const tiktokFollowers = influencer.tiktokStat ? influencer.tiktokStat.following : 0;
          const totalFollowers = instagramFollowers + tiktokFollowers;

          influencer.contractedCampaigns.forEach(campaign => {
            const campaignId = campaign.id;
            if (!campaignMap[campaignId]) {
              campaignMap[campaignId] = {
                users: [],
                totalFollowers: 0,
                tiktokFollowers: 0,
                instaFollowers: 0,
              };
            }
            campaignMap[campaignId].users.push(influencer.name);
            campaignMap[campaignId].totalFollowers += totalFollowers;
            campaignMap[campaignId].tiktokFollowers += tiktokFollowers;
            campaignMap[campaignId].instaFollowers += instagramFollowers;
            campaignMap[campaignId].startDate = campaign.startDate;
            campaignMap[campaignId].endDate = campaign.endDate;

          });
        });
        setCampaignData(campaignMap)
      }
    }
  });

  useEffect(() => {
    if (selectedCampaignIds) {
      refetchInfluencers()
    }
  }, [selectedCampaignIds, refetchInfluencers])

  useEffect(() => {
    let checkedCampaigns = campaigns?.filter((campaign) => selected.includes(campaign.name));
    let campaignResultData = {}

    if (checkedCampaigns?.length > 0) {
      for (let i = 0; i < checkedCampaigns.length; i++) {
        const campaign = checkedCampaigns[i];
        const key = campaign.name;
        const totalFollowers = campaignData[campaign?.id]?.totalFollowers
        let totalEngagement = 0;

        if (!campaignResultData[key]) {
          campaignResultData[key] = {
            campaignCER: 0,
            totalEngagement: 0
          };
        }

        for (const currentResult of campaign.campaignResults) {
          totalEngagement += currentResult.instaLikes + currentResult.instaComments +
                                                     currentResult.tiktokLikes + currentResult.tiktokComments
        }

        campaignResultData[key].campaignCER = formatResult((totalEngagement * 100) / totalFollowers)
        campaignResultData[key].totalEngagement = totalEngagement
      }
    }

    if (campaignData) {
      setUpdatedOptions({
        ...chartOptions,
        xaxis: {
          categories: checkedCampaigns?.map((campaign) => campaign.name),
        },
      });

      setSeries([
        {
          name: "CER",
          data: selected.map((campaign)  => {
            const currentCampaign = campaigns?.find(item => item.name == campaign)

            return {
              y: campaignResultData[campaign]?.campaignCER || 0,
              x: campaign,
              instaFollowers: campaignData[currentCampaign.id]?.instaFollowers?.toLocaleString(),
              tiktokFollowers: campaignData[currentCampaign.id]?.tiktokFollowers?.toLocaleString(),
              totalEngagement: campaignResultData[campaign]?.totalEngagement?.toLocaleString(),
              startDate: campaignResultData[campaign]?.startDate?.toLocaleString(),
              endDate: campaignResultData[campaign]?.endDate?.toLocaleString(),
            }
          })
        }
      ]);
    }
  }, [selected, influencers]);

  useEffect(() => {
    if (selectedBrand) {
      refetchCampaigns()
    }
  }, [selectedBrand, refetchCampaigns])

  const chartOptions = {
    chart: {
      foreColor: "#fff",
      type: "bar",
    },
    toolbar: {
      show: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            fontSize: "16px",
            position: "bottom",
            offsetX: -10,
            offsetY: 0,
          },
        },
      },
    ],
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    tooltip: {
      enabled: true,
      x: {
        show: true,
      },
      theme: 'dark',
      fillSeriesColor: true,
      custom: function({series, seriesIndex, dataPointIndex, w}) {
        var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
        return tooltipData(data)
      }
    },
    legend: {
      position: "right",
      offsetY: 40,
      fontSize: "16px",
      color: "white",
    },
    fill: {
      opacity: 1,
    },
  };
  const formatDate = (date) => {
    return moment.utc(date).format('DD/MM/YYYY') 
  }
  const tooltipData = (tooltipData) => {
    let tooltipContent =
      '<div class="tooltip">' +
        '<div class="heading-tooltip">' +
          '<div style="">' + '<strong>' + tooltipData?.x + '</strong>' + '</div>' +
        '</div>' +
        '<div class="data-tooltip-main">' +
          '<div style="data-value">CER:  ' + '<strong>' + tooltipData?.y  + '</strong>' + '</div>' +
          '<div style="data-value">Start Date:  ' + '<strong>' + formatDate(tooltipData?.startDate) + '</strong>' + '</div>' +
          '<div style="data-value">End Date:  ' + '<strong>' + formatDate(tooltipData?.endDate) + '</strong>' + '</div>' +
          '<div style="data-value">Total Engagement:  ' + '<strong>' + tooltipData?.totalEngagement + '</strong>' + '</div>' +
          '<div style="data-value">Insta Followers:  ' + '<strong>' + tooltipData?.instaFollowers  + '</strong>' + '</div>' +
          '<div style="data-value">Tiktok Followers:  ' + '<strong>' + tooltipData?.tiktokFollowers + '</strong>' + '</div>' +
        '</div>' +
      '</div>'
    return tooltipContent;
  }

  const handleBrandChange = (event) => {
    const value = event.target.value;
    setSelectedBrand(value);
  }

  const handleSelectionChange = (selectedCampaigns) => {
    let checkedCampaigns = campaigns.filter((campaign) => selectedCampaigns.includes(campaign.name))
    let campaignIds = checkedCampaigns.map(campaign => campaign.id);
    setSelectedCampaignIds([... new Set(campaignIds)]);
    setSelected(selectedCampaigns);
  };

  return (
    <Grid item xs container justifyContent="start">
      <Grid item xs={12} container justifyContent="space-between">
        <Typography variant="h5">
          Campaign Engagement Rate Comparison
        </Typography>
        <FormControl className={classes.formControl}>
          {userRole == "Admin" && (
            <BrandSelectDropdown
              labelId="brand-select-label"
              brands={brands}
              selectedBrand={selectedBrand}
              handleChange={handleBrandChange}
              />
            )
          }
          <div style={{display: "flex", alignItems: 'center', marginTop: 10}}>
            <CampaignSelectDropdown
              campaigns={campaigns}
              onSelectionChange={handleSelectionChange}
              classes={classes}
              MenuProps={MenuProps}
            />
            <div style={{justifyContent: 'space-between',  display: 'flex' }}>
              <div style={{ flex: 1, margin: 5 }}>
                <PublishButton
                  graphId={chart?.id}
                  userId={selectedBrand}
                  campaignIds={selectedCampaignIds}
                  userRole={userRole}
                />
              </div>
              <div style={{flex: 1, margin: 5}}>
                <UnpublishButton
                  graphId={chart?.id}
                  userId={selectedBrand}
                  campaignIds={selectedCampaignIds}
                  userRole={userRole}
                />
              </div>
            </div>
          </div>
        </FormControl>
      </Grid>
      <Chart
        options={updatedOptions}
        series={series}
        type="bar"
        height={height / 1.6}
        width={width - 300}
      />
    </Grid>
  );
};

export default CerComparisonChart;

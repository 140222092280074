import { gql } from "@apollo/client";

export const GET_BRAND_CAMPAIGN_NAMES = gql`
  query getBrandCampaignNames($brandId: ID!) {
    getBrandCampaignNames(brandId: $brandId) {
      id
      name
    }
  }
`;

export const GET_BRAND_CAMPAIGN_USERS_DATA = gql`
  query getBrandCampaignUsersData($campaignId: ID!, $userId: ID) {
    getBrandCampaignUsersData(campaignId: $campaignId, userId: $userId) {
      results
    }
  }
`;

import React, { useState, useEffect } from "react";
import {
  Grid,
  Modal,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import GET_CAMPAIGN_CATEGORIES from "../../graphql/queries/getCampaignCategories";
import { PinkButton } from "../common-components/customComponents";
import useStyles from "./styles";
import { useMutation, useQuery } from "@apollo/client";
import UPDATE_USER_PROFILE from "../../graphql/mutations/updateUserInfo";

const countries = ["England", "Northern Ireland", "Scotland", "Wales", "Other"];
const cities = {
  England: [
    "Bath",
    "Birmingham",
    "Bradford",
    "Brighton and Hove",
    "Bristol",
    "Cambridge",
    "Canterbury",
    "Carlisle",
    "Chelmsford",
    "Chester",
    "Chichester",
    "Colchester",
    "Coventry",
    "Derby",
    "Doncaster",
    "Durham",
    "Ely",
    "Exeter",
    "Gloucester",
    "Hereford",
    "Kingston upon Hull",
    "Lancaster",
    "Leeds",
    "Leicester",
    "Lichfield",
    "Lincoln",
    "Liverpool",
    "London",
    "Manchester",
    "Milton Keynes",
    "Newcastle upon Tyne",
    "Norwich",
    "Nottingham",
    "Oxford",
    "Peterborough",
    "Plymouth",
    "Portsmouth",
    "Preston",
    "Ripon",
    "Salford",
    "Salisbury",
    "Sheffield",
    "Southampton",
    "Southend-on-Sea",
    "St Albans",
    "Stoke-on-Trent",
    "Sunderland",
    "Truro",
    "Wakefield",
    "Wells",
    "Westminster",
    "Winchester",
    "Wolverhampton",
    "Worcester",
    "York",
  ],
  "Northern Ireland": [
    "Armagh",
    "Bangor",
    "Belfast",
    "Lisburn",
    "Londonderry",
    "Newry",
  ],
  Scotland: [
    "Aberdeen",
    "Dundee",
    "Dunfermline",
    "Edinburgh",
    "Glasgow",
    "Inverness",
    "Perth",
    "Stirling",
  ],
  Wales: [
    "Bangor",
    "Cardiff",
    "Newport",
    "St Asaph (Llanelwy)",
    "St Davids",
    "Swansea",
    "Wrexham",
  ],
};

const EditProfile = ({ open, setOpen, storedProfileInfo }) => {
  console.log(storedProfileInfo)
  const classes = useStyles();
  const [profileInfo, setProfileInfo] = useState({
    userId: localStorage.getItem("currentUser"),
    name: storedProfileInfo.name || "",
    email: storedProfileInfo.email || "",
    country: storedProfileInfo.country || "",
    city: storedProfileInfo.city || "",
    instagram: storedProfileInfo.instagram || "",
    tiktok: storedProfileInfo.tiktok || "",
    facebook: storedProfileInfo.facebook || "",
    website: storedProfileInfo.website || "",
    youtube: storedProfileInfo.youtube || "",
    category: storedProfileInfo.category || [],
  });

  const { data: categories } = useQuery(GET_CAMPAIGN_CATEGORIES);
  const [updateProfile] = useMutation(UPDATE_USER_PROFILE, {
    onCompleted: (res) => {
      handleClose();
    },
    refetchQueries: ["getBrand"],
  });

  const handleTextfieldChange = (event) => {
    const { id, value } = event.currentTarget;
    setProfileInfo((prevInfo) => ({ ...prevInfo, [id]: value }));
  };

  const handleDropdownChange = (event) => {
    const { name, value } = event.target;
    setProfileInfo((prevInfo) => ({ ...prevInfo, [name]: value }));
  };

  const handleCategoryChange = (event) => {
    const { value } = event.target;
    setProfileInfo((prevInfo) => ({ ...prevInfo, category: [{ id: value }] }));
  };

  const handleClose = () => {
    setProfileInfo({
      userId: localStorage.getItem("currentUser"),
      name: "",
      email: "",
      country: "",
      city: "",
      instagram: "",
      tiktok: "",
      facebook: "",
      website: "",
      youtube: "",
      category: [],
    });
    setOpen(false);
  };

  const handleSave = () => {
    const finalVariables = { ...profileInfo };
    // Ensure category is correctly formatted for the mutation
    if (finalVariables.category.length > 0) {
      finalVariables.categories = finalVariables.category.map(cat => cat.id);
    }
    Object.keys(profileInfo).forEach((key) => {
      if (finalVariables[key] === "") delete finalVariables[key];
    });
    updateProfile({
      variables: { ...finalVariables },
    }).then(() => {
      window.location.reload();
    });
  };

  return (
      <Modal open={open} onClose={handleClose} aria-labelledby="edit-profile-modal">
        <Grid item xs={12} container className={classes.modalStyle} justifyContent="center">
          <Typography variant="h5" style={{ marginBottom: 20 }}>Update Profile</Typography>
          <Grid item xs={12} container sx={{ overflowY: "scroll" }} justifyContent="center">
            <TextField
                fullWidth
                variant="standard"
                id="name"
                value={profileInfo.name}
                label="User Name"
                onChange={handleTextfieldChange}
                placeholder="Type to Change"
                sx={{ marginBottom: 3 }}
            />
            <TextField
                fullWidth
                variant="standard"
                id="email"
                value={profileInfo.email}
                label="Email"
                onChange={handleTextfieldChange}
                placeholder="Type to Change"
                sx={{ marginBottom: 3 }}
            />
            <TextField
                fullWidth
                variant="standard"
                id="instagram"
                value={profileInfo.instagram}
                label="Instagram Handle"
                onChange={handleTextfieldChange}
                placeholder="Type to Change"
                sx={{ marginBottom: 3 }}
            />
            <TextField
                fullWidth
                variant="standard"
                id="tiktok"
                value={profileInfo.tiktok}
                label="TikTok Handle"
                onChange={handleTextfieldChange}
                placeholder="Type to Change"
                sx={{ marginBottom: 3 }}
            />
            <TextField
                fullWidth
                variant="standard"
                id="facebook"
                value={profileInfo.facebook}
                label="Facebook Handle"
                onChange={handleTextfieldChange}
                placeholder="Type to Change"
                sx={{ marginBottom: 3 }}
            />
            <TextField
                fullWidth
                variant="standard"
                id="youtube"
                value={profileInfo.youtube}
                label="Youtube Channel URL"
                onChange={handleTextfieldChange}
                placeholder="Type to Change"
                sx={{ marginBottom: 3 }}
            />
            <TextField
                fullWidth
                variant="standard"
                id="website"
                value={profileInfo.website}
                label="Website URL"
                onChange={handleTextfieldChange}
                placeholder="Type to Change"
                sx={{ marginBottom: 3 }}
            />
            <FormControl fullWidth sx={{ marginBottom: 3 }}>
              <InputLabel id="niche">Niche</InputLabel>
              <Select
                  labelId="niche"
                  name="niche"
                  value={profileInfo.category.length > 0 ? profileInfo.category[0].id : ""}
                  label="Niche"
                  variant="standard"
                  onChange={handleCategoryChange}
              >
                {categories?.getCampaignCategories.map((category, index) => (
                    <MenuItem value={category.id} key={`category-${category.id}-${index}`}>
                      {category.name}
                    </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth sx={{ marginBottom: 3 }}>
              <InputLabel id="country">Country</InputLabel>
              <Select
                  labelId="country"
                  name="country"
                  value={profileInfo.country}
                  label="Country"
                  variant="standard"
                  onChange={handleDropdownChange}
              >
                {countries.map((country, index) => (
                    <MenuItem value={country} key={`country-${index}`}>
                      {country}
                    </MenuItem>
                ))}
              </Select>
              {profileInfo.country === "Other" && (
                  <FormHelperText sx={{ color: "rgba(239, 46, 131, 1)" }}>
                    To have your country listed here, contact site admins
                  </FormHelperText>
              )}
            </FormControl>
            {profileInfo.country !== "" && profileInfo.country !== "Other" && (
                <FormControl fullWidth sx={{ marginBottom: 3 }}>
                  <InputLabel id="city">City</InputLabel>
                  <Select
                      labelId="city"
                      name="city"
                      value={profileInfo.city}
                      label="City"
                      variant="standard"
                      onChange={handleDropdownChange}
                  >
                    {cities[profileInfo.country].map((city, index) => (
                        <MenuItem value={city} key={`city-${index}`}>
                          {city}
                        </MenuItem>
                    ))}
                  </Select>
                </FormControl>
            )}
            <PinkButton variant="contained" sx={{ marginRight: 2 }} onClick={handleSave}>
              Submit
            </PinkButton>
            <PinkButton variant="contained" onClick={handleClose}>
              Cancel
            </PinkButton>
          </Grid>
        </Grid>
      </Modal>
  );
};

export default EditProfile;
import { Button } from "@mui/material";
import { withStyles } from "@mui/styles";

export const PinkButton = withStyles({
  root: {
    padding: 2,
    height: 40,
    color: "#fff !important",
    backgroundColor: "rgba(239, 46, 131, 1) !important",
    "&:hover": {
      backgroundColor: "rgba(239, 46, 131, 0.5) !important",
    },
    "&.Mui-disabled": {
      backgroundColor: "#bdc3c7 !important",
    },
  },
})(Button);

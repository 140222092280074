import { gql } from "@apollo/client";

const GET_ALL_CAMPAIGNS = gql`
  query getAllCampaigns($userId: ID!, $userRole: String $graphId: ID) {
    getAllCampaigns(userId: $userId, userRole: $userRole, graphId: $graphId) {
      id
      name
      cost
      userId
      startDate
      endDate
      campaignResults {
        id
        instaImpressions
        instaLikes
        instaComments
        instaFollowers
        instaStoryViews
        instaReach
        instaPlays
        tiktokLikes
        tiktokComments
        tiktokFollowers
        tiktokViews
        tiktokShares
        discountCodeUsed
        instaNonFollowers
        instaSaves
        instaShares
        instaProfileVisits
        instaAccountsReached
        instaAccountsEngaged
        instaLinkClicks
        tiktokAverageWatchTime
        tiktokWatchFullVideo
        tiktokPlays
        tiktokSaves
        tiktokShares
      }
    }
  }
`

export default GET_ALL_CAMPAIGNS;

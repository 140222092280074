import { gql } from "@apollo/client";

const INVITE_TO_CAMPAIGN = gql`
  mutation brandInviteInfluencers(
    $campaignId: ID!
    $influencerId: [ID!]!
  ) {
    brandInviteInfluencers(
      campaignId: $campaignId
      influencerId: $influencerId
    )
  }
`;

export default INVITE_TO_CAMPAIGN;

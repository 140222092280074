import React, { useState } from 'react';
import {
  Select,
  MenuItem,
  Checkbox,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

const CampaignSelectDropdown = ({
  campaigns,
  classes,
  onSelectionChange,
  MenuProps
}) => {
  const [selected, setSelected] = useState([]);
  const [allSelected, setAllSelected] = useState(false);

  const handleChange = (event) => {
    const value = event.target.value;
    let newSelected = [];

    if (value.includes('all') || value.length === campaigns.length + 1 || value.length === campaigns.length) {
      if (!allSelected) {
        newSelected = campaigns.map((campaign) => campaign.name);
        setAllSelected(true);
      } else {
        newSelected = [];
        setAllSelected(false);
      }
    } else {
      if (allSelected && value.length < campaigns.length) {
        const deselectedCampaign = selected.find(name => !value.includes(name));
        newSelected = selected.filter(name => name !== deselectedCampaign);
      } else {
        newSelected = value;
      }
      setAllSelected(false);
    }

    setSelected(newSelected);
    if (onSelectionChange) {
      onSelectionChange(newSelected); // Lift state up if parent component needs access to the selected values
    }
  };

  return (
    <>
      <Select
        displayEmpty
        multiple
        value={selected}
        variant="standard"
        onChange={handleChange}
        sx={{
          color: "#FFF",
          background: "#FFA3C7",
          borderRadius: 1,
          padding: 1,
          width: 200
        }}
        renderValue={selected.length > 0 ? (selected) => selected.join(", ") : () => <em>Select Campaigns</em>}
        MenuProps={MenuProps}
      >
        <MenuItem
          value="all"
          classes={{
            root: allSelected ? classes.selectedAll : "",
          }}
        >
          <ListItemIcon>
            <Checkbox
              classes={{ indeterminate: classes.indeterminateColor }}
              checked={allSelected}
              indeterminate={selected.length > 0 && selected.length < campaigns.length}
            />
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.selectAllText }}
            primary="Select All"
          />
        </MenuItem>
        {campaigns?.map((option) => (
          <MenuItem key={option.id} value={option.name}>
            <ListItemIcon>
              <Checkbox checked={selected.indexOf(option.name) > -1} />
            </ListItemIcon>
            <ListItemText primary={option.name} />
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

export default CampaignSelectDropdown;

import React, { useState } from "react";
import { Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useMutation } from "@apollo/client";
import INVITE_TO_CAMPAIGN from "../../graphql/mutations/inviteToCampaign";
import { TextField } from "@mui/material";

export default function CampaignInviteDialog({
  open,
  setOpen,
  campaignId,
  influencerId,
  campaignType,
}) {
  const [inviteToCampaign] = useMutation(INVITE_TO_CAMPAIGN);
  const [description, setDescription] = useState("");
  function handleClose() {
    setOpen(false);
  }
  function handleInvite() {
    inviteToCampaign({
      variables: {
        campaignId: campaignId,
        influencerId: [influencerId],
        description: description,
      },
      refetchQueries: ["getInvitedInfluencers"],
    });
    handleClose();
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="invite-to-campaign-dialog-title"
        sx={{ padding: 3 }}
      >
        <DialogTitle id="invite-to-campaign-dialog-title">
          Please fill out invite details
        </DialogTitle>
        <DialogContent sx={{ width: 330 }}>
          <TextField
            id="campaign-explanation"
            fullWidth
            label="Brief explanation of the work required"
            variant="standard"
            multiline
            rows={4}
            onChange={(e) => setDescription(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleInvite} autoFocus>
            Invite
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
